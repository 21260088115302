import { AxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';

import { createCapitalCounselServices } from '@/api/AdditionalServices/createAdditionalServices';
import { fetchChatRoomList } from '@/api/chat/getChat';
import FloatingIconButton from '@/components/Common/Button/FloatingIconButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import { PhoneIcon } from '@/components/Icon';
import ApplyCapitalServiceForm from '@/components/More/CaptialCounselService/ApplyCapitalServiceForm';
import CapitalGuideView from '@/components/More/CaptialCounselService/CapitalGuideView';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useNavigateBack from '@/hooks/useNavigateBack';
import { phoneCall } from '@/utils/common';

const CapitalCounselService = () => {
  const { showToast } = useToastContext();
  const { member } = useMemberContext();
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const productInfo = location.state?.productInfo;

  const productPrice = location.state?.productPrice;

  const isScrollBottom = location.state?.isScrollBottom || false;

  const PurchaseAccompanyingServiceRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isScrollBottom) {
      scrollBottom();
    }
  }, [isScrollBottom]);

  const [isPopupShow, setIsPopupShow] = useState(false);
  const [requestForm, setRequestForm] = useState<AdditionalServicesApplyForm>();

  const updateRequestForm = (formData: AdditionalServicesApplyForm) => {
    setRequestForm(formData);
  };

  const {
    data: chattingList,
    isLoading,
    isFetching,
  } = useQuery<ChattingListResponse[]>(['get-chat-room-list'], () => fetchChatRoomList(), {
    onSuccess: () => {},
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    keepPreviousData: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (member) {
      setRequestForm({
        ...requestForm,
        name: { value: member?.name, error: false },
        phoneNumber: { value: member?.phoneNumber, error: false },
        productId: productInfo?.productId,
        chatRoomId: productInfo?.chatRoomId,
        truckName: productInfo?.truckName,
      });
    }
  }, [member, productInfo]);

  const scrollBottom = () => {
    setTimeout(() => {
      if (PurchaseAccompanyingServiceRef.current) {
        window.scroll({
          top: PurchaseAccompanyingServiceRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 10);
  };

  const handleClickApplyService = () => {
    setIsPopupShow(true);
  };

  const handleClickClosePopup = () => {
    setIsPopupShow(false);
  };

  const handleClickOkBtn = () => {
    if (requestForm) {
      const requestData = {
        name: requestForm.name?.value,
        phoneNumber: requestForm.phoneNumber?.value,
        productId: requestForm.productId,
      };
      postCapitalCounselServices.mutate(requestData);
      setIsPopupShow(false);
    }
  };

  const postCapitalCounselServices = useMutation(
    (request: AdditionalServicesApplyRequest) => createCapitalCounselServices(request),
    {
      onSuccess: () => {
        showToast('화물차 대출 상담 서비스를 신청했어요.', 'success', 'bottom');
      },
      onError: (error: AxiosError) => {
        if (error.code && error.message) {
          showToast(error.message, 'error', 'bottom');
        } else {
          showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
        }
      },
    },
  );

  const handleClickPrev = () => {
    navigateBack();
  };

  return (
    <>
      <MenuHeader title="화물차 대출 상담 서비스" onClickPrevBtn={handleClickPrev}></MenuHeader>

      <div className="w-full pt-[60px]" ref={PurchaseAccompanyingServiceRef}>
        <CapitalGuideView price={productPrice || 5000} />
        <ApplyCapitalServiceForm
          isShowVehicleSelector={(chattingList && chattingList?.length > 0) || false}
          formData={requestForm || {}}
          updateFormData={(data: AdditionalServicesApplyForm) => updateRequestForm(data)}
        />
        <FloatingIconButton
          bgColor={colors.gray[0]}
          icon={<PhoneIcon color={colors.primary}></PhoneIcon>}
          width={52}
          height={52}
          onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
        ></FloatingIconButton>
        <div className="bg-white px-4 py-4">
          <ButtonFooter
            title="서비스 신청하기"
            isDisabled={
              !requestForm?.name?.value ||
              !requestForm?.phoneNumber?.value ||
              requestForm?.name?.error ||
              requestForm?.phoneNumber?.error
            }
            onClick={handleClickApplyService}
          ></ButtonFooter>
        </div>
      </div>

      <BasicPopup
        isShow={isPopupShow}
        title={`${!!requestForm?.productId ? requestForm?.truckName : ''}`}
        textContent={`${
          requestForm?.productId
            ? '선택한 차량으로 차량 구매 동행<br/>서비스를 신청할까요?'
            : '화물차 대출 상담 서비스를<br>신청하시겠어요?'
        }`}
        textLeftBtn="취소"
        onClickLeftBtn={() => handleClickClosePopup()}
        textRightBtn="신청하기"
        onClickRightBtn={() => handleClickOkBtn()}
      ></BasicPopup>
    </>
  );
};

export default CapitalCounselService;
