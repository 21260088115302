// 직거래 판매 대행 서비스 신청 컴포넌트
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { postOneStopService } from '@/api/public';
import FloatingIconButton from '@/components/Common/Button/FloatingIconButton';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ButtonFooter from '@/components/Footer/ButtonFooter';
import MenuHeader from '@/components/Header/MenuHeader';
import {
  CheckOutlineIcon,
  HeadphoneColorIcon,
  PhoneIcon,
  VehicleInspectionPlusColorIcon,
  VehicleInspectionSellColorIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';
import { REPRESENTATIVE_NUMBER } from '@/const/common';
import {
  NAME_VALIDATION_LENGTH_MESSAGE,
  NAME_VALIDATION_MESSAGE,
  PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE,
} from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import { phoneCall } from '@/utils/common';
import { Validation, validateName, validatePhoneNumber } from '@/utils/validation';

export interface OneStopServiceRequestData {
  name?: string;
  phoneNumber?: string;
}

const OneStopServiceRequest = () => {
  const { member } = useMemberContext();
  const { showToast } = useToastContext();

  const [isDisabledRequestButton, setIsDisabledRequestButton] = useState(true);
  const [isPopupShow, setIsPopupShow] = useState(false);

  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

  const [request, setRequest] = useState<OneStopServiceRequestData>({
    name: '',
    phoneNumber: '',
  });

  useEffect(() => {
    if (member) {
      validateNameAndPhoneNumber(member?.name, member?.phoneNumber);
      setRequest({
        name: member?.name,
        phoneNumber: member?.phoneNumber,
      });
    }
  }, [member]);

  const validateNameAndPhoneNumber = (name?: string, phoneNumber?: string) => {
    if (name) {
      if (name.length > 20) {
        setNameErrorMessage(NAME_VALIDATION_LENGTH_MESSAGE);
        setNameError(true);
      } else if (validateName(name) === false) {
        setNameErrorMessage(NAME_VALIDATION_MESSAGE);
        setNameError(true);
      } else {
        setNameErrorMessage('');
        setNameError(false);
      }
    }

    if (phoneNumber) {
      if (phoneNumber.length > 11) {
        setPhoneNumberErrorMessage(PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE);
        setPhoneNumberError(true);
      } else {
        const phoneNumberValidation = validatePhoneNumber(phoneNumber) as Validation;
        if (phoneNumberValidation.isValid === false) {
          setPhoneNumberErrorMessage(phoneNumberValidation.errorMessage);
          setPhoneNumberError(true);
        } else {
          setPhoneNumberErrorMessage('');
          setPhoneNumberError(false);
        }
      }
    }
  };

  const onChangeRequestInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {
      validateNameAndPhoneNumber(value, request?.phoneNumber);
      setRequest((prev) => ({ ...prev, name: value }));
    } else {
      validateNameAndPhoneNumber(request.name, value);
      setRequest((prev) => ({ ...prev, phoneNumber: value }));
    }
  }, []);

  const { mutate, isLoading, isError, isSuccess } = useMutation(postOneStopService, {
    onSuccess: () => {
      showToast('판매 대행 서비스 신청이 완료되었어요.', 'success', 'bottom');
    },
    onError: (error: AxiosError) => {
      if (error.code && error.message) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast('문제가 생겼어요. 다시 시도해주세요.', 'error', 'bottom');
      }
    },
  });

  const onClickRequestOneStopService = () => {
    setIsPopupShow(true);
  };

  const onClickLeftBtn = () => {
    setIsPopupShow(false);
  };

  const onClickRightBtn = () => {
    mutate(request);
    setIsPopupShow(false);
  };

  useEffect(() => {
    setIsDisabledRequestButton(nameError || phoneNumberError || isLoading || isError || isSuccess);
  }, [nameError, phoneNumberError, isLoading, isError, isSuccess]);

  return (
    <>
      <MenuHeader title="판매 대행 서비스"></MenuHeader>

      <div className="w-full pt-[60px]">
        <div className="flex flex-col items-center">
          <p className="text-gray-8 text-[24px] font-bold text-center pt-6 pb-9">
            <span className="sm280:hidden">직거래 </span>판매 대행 서비스란?
          </p>
          <div className="text-center">
            차량 판매 등록, 구매자 상담,
            <br className="hidden sm280:block" /> 거래 완료 시점까지
            <br className="sm280:hidden sm360:block sm680:hidden" />
            모든 판매 과정을
            <br className="hidden sm280:block" /> 한번에 도와드리는 서비스입니다.
          </div>

          <div className="flex flex-wrap justify-center gap-[12px] sm680:gap-[40px] py-[34px]">
            <div className="flex flex-col justify-center items-center bg-gray-1 w-[90px] h-[90px] rounded-[25px]">
              <VehicleInspectionPlusColorIcon width={37} height={37} />
              <div className="text-center font-semibold text-gray-8 text-[14px] leading-[16px] mt-[6px]">
                차량
                <br />
                판매 등록
              </div>
            </div>
            <div className="flex flex-col justify-center items-center bg-gray-1 w-[90px] h-[90px] rounded-[25px]">
              <HeadphoneColorIcon width={36} height={36} />
              <div className="text-center font-semibold text-gray-8 text-[14px] leading-[16px] mt-[6px]">
                구매자
                <br />
                고객 상담
              </div>
            </div>
            <div className="flex flex-col justify-center items-center bg-gray-1 w-[90px] h-[90px] rounded-[25px]">
              <VehicleInspectionSellColorIcon width={36} height={36} />
              <div className="text-center font-semibold text-gray-8 text-[14px] leading-[16px] mt-[6px]">
                차량
                <br />
                판매 완료
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gray-1 py-[46px] px-4 flex flex-col justify-center items-center">
          <h4 className="text-gray-8 font-bold text-[24px] mb-[24px]">이런 분에게 추천해요</h4>
          <ul className="flex flex-col gap-[14px] text-[15px] font-medium break-keep ">
            <li className="flex gap-1">
              <CheckOutlineIcon color={colors.primary} />
              어플 사용에 어려움을 느끼시는 분
            </li>
            <li className="flex gap-1">
              <CheckOutlineIcon color={colors.primary} />
              시간 제약으로 구매자 응대가 어려우신 분
            </li>
            <li className="flex gap-1">
              <CheckOutlineIcon color={colors.primary} />
              <span className="text-pretty">거래 절차가 번거롭게 느껴지시는 분</span>
            </li>
          </ul>
        </div>

        <div className="flex flex-col items-center py-11 border-b-8 border-gray-1">
          <p className="text-2xl text-gray-8 text-center font-bold pb-8">직거래도 스마트하게!</p>
          <p className="text-gray-8 text-center pb-5 text-pretty mx-7 font-medium text-[14px] leading-[28px]">
            고객님은 안전하게 차량 운행만 하세요!
            <br className="sm680:hidden" /> 모든 절차는 직트럭이 도와드리겠습니다.
          </p>
          <div className="w-[200px]">
            <img
              src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/zig_truck_img.png"
              alt="zig_truck_img"
              className="w-full"
            ></img>
          </div>
          <div className="text-gray-6 text-[12px] mt-[34px]">※ 해당 서비스는 유료 서비스입니다.</div>
        </div>

        <div className="px-4 pt-6">
          <div className="flex flex-col gap-[30px]">
            <TextInputLabelUp
              name="name"
              label="신청자명"
              placeholder="신청자명을 입력해주세요."
              value={request.name || ''}
              onChange={onChangeRequestInput}
              error={nameError}
              errorMsg={nameErrorMessage}
            ></TextInputLabelUp>

            <TextInputLabelUp
              name="phoneNumber"
              label="휴대폰 번호"
              placeholder="휴대폰 번호를 입력해주세요."
              value={request.phoneNumber || ''}
              onChange={onChangeRequestInput}
              error={phoneNumberError}
              errorMsg={phoneNumberErrorMessage}
            ></TextInputLabelUp>
          </div>
        </div>

        <FloatingIconButton
          bgColor={colors.gray[0]}
          icon={<PhoneIcon color={colors.primary}></PhoneIcon>}
          width={52}
          height={52}
          onClick={() => phoneCall(REPRESENTATIVE_NUMBER)}
        ></FloatingIconButton>

        <div className="bg-white px-4 py-2">
          <ButtonFooter
            title="서비스 신청하기"
            isDisabled={isDisabledRequestButton}
            onClick={onClickRequestOneStopService}
          ></ButtonFooter>
        </div>
      </div>
      <BasicPopup
        isShow={isPopupShow}
        textContent="판매 대행 서비스를<br>신청하시겠어요?"
        textLeftBtn="닫기"
        onClickLeftBtn={() => onClickLeftBtn()}
        textRightBtn="확인"
        onClickRightBtn={() => onClickRightBtn()}
      ></BasicPopup>
    </>
  );
};

export default OneStopServiceRequest;
