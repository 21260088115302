import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface ToastType {
  id: number;
  message: string;
  type: string;
  position: string;
}

const Toast = ({ id, message, type, position }: ToastType) => {
  const getStyle = () => {
    switch (type) {
      case 'error':
        return 'bg-red';
      case 'success':
        return 'bg-gray-8';
      default:
        return '';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'error':
        return <ErrorOutlineIcon />;
      case 'success':
        return <CheckCircleIcon />;
      default:
        return null;
    }
  };

  const getPosition = () => {
    switch (position) {
      case 'top':
        return 'top-10';
      case 'bottom':
      default:
        return 'bottom-10';
    }
  };

  return (
    <div
      className={`fixed z-[1500] left-0 right-0 mx-auto my-0 px-4 max-w-[720px] flex justify-center ${getPosition()}`}
    >
      <div className={`text-white opacity-90 rounded-lg py-2 px-3 ${getStyle()} w-auto inline`}>
        {getIcon()}
        <span className="ml-2">{message}</span>
      </div>
    </div>
  );
};

export default Toast;
