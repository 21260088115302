import { atom } from 'jotai';

import { isExistNotReadChatMessageAtom } from '@/store/chatting';
import { isExistNotReadNotificationAtom } from '@/store/notification';

export const memberAtom = atom<Member | null>(null);
export const isMemberLoadedAtom = atom((get) => get(memberAtom) !== null);

export const loginAtom = atom(null, (get, set, member: Member) => {
  set(memberAtom, member);
});

export const logoutAtom = atom(null, (get, set) => {
  set(memberAtom, null);
});

export const updateMemberAtom = atom(null, (get, set, member: Member) => {
  set(memberAtom, member);
});

export const removeMemberAtom = atom(null, (get, set) => {
  set(memberAtom, null);
});

export const updateNotificationSettingsAtom = atom(
  null,
  (get, set, { key, checked }: { key: string; checked: boolean | number }) => {
    const currentMember = get(memberAtom);
    if (currentMember) {
      const updatedSettings = { ...currentMember.notificationSettings, [key]: checked };
      set(memberAtom, { ...currentMember, notificationSettings: updatedSettings });
    }
  },
);

export const updateDriveVehicleInfoAtom = atom(null, (get, set, data: DriveVehicleInfo) => {
  const currentMember = get(memberAtom);
  if (currentMember) {
    set(memberAtom, { ...currentMember, driveVehicleInfo: { ...currentMember.driveVehicleInfo, ...data } });
  }
});

export const updateReferralCodesAtom = atom(null, (get, set, data: ReferralCode) => {
  const currentMember = get(memberAtom);
  if (currentMember) {
    set(memberAtom, { ...currentMember, referralCodes: [...currentMember.referralCodes, data] });
  }
});

// TODO: memberContext에 있는 값 모두 atom으로 이관시 member 조회 api 추가
export const getMemberAtom = atom(null, (get, set, memberData: Member) => {
  try {
    set(memberAtom, memberData);
    set(isExistNotReadNotificationAtom, !!memberData.isExistNotReadNotification);
    set(isExistNotReadChatMessageAtom, !!memberData.isExistNotReadChatMessage);
  } catch (error) {
    console.error('Failed to set member data:', error);

    const keysToRemove = ['token', 'driveTutorial', 'driveTutorialReceivedCheck'];
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }
});

export const setIsMemberLoadedAtom = atom(null, (get, set) => {
  const currentMember = get(memberAtom);

  // 현재 값이 null이라면 빈 객체로 설정
  if (currentMember === null) {
    set(memberAtom, {} as Member); // 빈 객체로 설정 (Member 타입 강제 적용)
  }
});
