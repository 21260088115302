import React from 'react';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import { colors } from '@/const/colors';

type Props = {
  title?: string;
  bgColor?: string;
  borderColor?: string;
  textColor?: string;
  buttons?: Button[];
  isDisabled?: boolean;
  path?: string;
  onClick?: (() => void) | undefined;
};

const ButtonFooter = ({ title, isDisabled, path, onClick, bgColor, borderColor, textColor, buttons }: Props) => {
  const navigate = useNavigate();

  const onClickBtn = (button?: Button) => {
    if (button) {
      if (button.onClick) {
        button.onClick();
      } else if (button.path) {
        navigate(button.path);
      }
    } else {
      if (onClick) {
        onClick();
      } else if (path) {
        navigate(path);
      }
    }
  };

  return (
    <>
      {buttons ? (
        buttons.map((item, index) => (
          <div className={`flex-1 bg-white ${item.flex} ${index === 0 ? '' : 'ml-2'}`} key={index}>
            <div
              className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
            ></div>
            <BasicButton
              name={item.title}
              bgColor={item.bgColor ? item.bgColor : colors.primary}
              borderColor={item.borderColor ? item.borderColor : colors.primary}
              textColor={item.textColor ? item.textColor : colors.gray[0]}
              fontSize={16}
              height={48}
              fontWeight={'bold'}
              onClick={() => onClickBtn(item)}
              isDisabled={item.isDisabled}
            />
            {item.description ? (
              <div className="text-[12px] xxs:text-[11px] text-primary text-center mt-2">
                {item.description?.split('\n').map((text, index) => {
                  return (
                    <span key={index}>
                      {text}
                      <br />
                    </span>
                  );
                })}
              </div>
            ) : null}
          </div>
        ))
      ) : (
        <div className="w-full flex-1 bg-white">
          <div
            className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
          ></div>
          <BasicButton
            name={title || ''}
            bgColor={bgColor ? bgColor : colors.primary}
            borderColor={borderColor ? borderColor : colors.primary}
            textColor={textColor ? textColor : colors.gray[0]}
            fontSize={16}
            height={48}
            fontWeight={'bold'}
            onClick={() => onClickBtn()}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </>
  );
};

export default ButtonFooter;
