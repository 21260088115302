import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import { ChevronRightIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import {
  NAME_VALIDATION_LENGTH_MESSAGE,
  NAME_VALIDATION_MESSAGE,
  PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE,
} from '@/const/errorMessage';
import { Validation, validateName, validatePhoneNumber } from '@/utils/validation';

const ApplyTransferAgencyServiceForm = ({
  formData,
  updateFormData,
  isShowVehicleSelector,
}: ApplyAdditionalServiceFormProps) => {
  const navigate = useNavigate();
  const [name, setName] = useState(formData.name?.value || '');
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber?.value || '');
  const [selectedVehicle, setSelectedVehicle] = useState(formData?.truckName || '');
  useEffect(() => {
    if (formData) {
      setName(formData.name?.value || '');
      setPhoneNumber(formData.phoneNumber?.value || '');
      setSelectedVehicle(formData?.truckName || '');
    }
  }, [formData]);

  const [nameError, setNameError] = useState(false);
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');

  const handleClickVehicleSelect = () => {
    navigate('/transfer-agency-service/select', { state: { chatRoomId: formData.chatRoomId } });
  };

  const validateNameAndPhoneNumber = (name?: string, phoneNumber?: string) => {
    if (name) {
      if (name.length > 20) {
        setNameErrorMessage(NAME_VALIDATION_LENGTH_MESSAGE);
        setNameError(true);
      } else if (validateName(name) === false) {
        setNameErrorMessage(NAME_VALIDATION_MESSAGE);
        setNameError(true);
      } else {
        setNameErrorMessage('');
        setNameError(false);
      }
    }

    if (phoneNumber) {
      if (phoneNumber.length > 11) {
        setPhoneNumberErrorMessage(PHONE_NUMBER_VALIDATION_LENGTH_MESSAGE);
        setPhoneNumberError(true);
      } else {
        const phoneNumberValidation = validatePhoneNumber(phoneNumber) as Validation;
        if (phoneNumberValidation.isValid === false) {
          setPhoneNumberErrorMessage(phoneNumberValidation.errorMessage);
          setPhoneNumberError(true);
        } else {
          setPhoneNumberErrorMessage('');
          setPhoneNumberError(false);
        }
      }
    }
  };

  const onChangeRequestInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'name') {
      validateNameAndPhoneNumber(value, phoneNumber);
      setName(value);
    } else {
      validateNameAndPhoneNumber(name, value);
      setPhoneNumber(value);
    }
  }, []);

  useEffect(() => {
    updateFormData({
      ...formData,
      name: { value: name, error: nameError },
      phoneNumber: { value: phoneNumber, error: phoneNumberError },
    });
  }, [name, phoneNumber]);

  return (
    <div className="px-4 py-[30px] bg-gray-0">
      <div className="flex flex-col gap-[30px]">
        <TextInputLabelUp
          name="name"
          label="신청자명"
          placeholder="신청자명을 입력해주세요."
          value={name || ''}
          onChange={onChangeRequestInput}
          error={nameError}
          errorMsg={nameErrorMessage}
        ></TextInputLabelUp>

        <TextInputLabelUp
          name="phoneNumber"
          label="휴대폰 번호"
          placeholder="휴대폰 번호를 입력해주세요."
          value={phoneNumber || ''}
          onChange={onChangeRequestInput}
          error={phoneNumberError}
          errorMsg={phoneNumberErrorMessage}
          type="number"
        ></TextInputLabelUp>
        {isShowVehicleSelector && (
          <div onClick={handleClickVehicleSelect}>
            <TextInputLabelUp
              name="차량"
              label="차량 선택하기"
              placeholder="차량 선택"
              value={selectedVehicle || ''}
              onChange={() => {}}
              suffix={<ChevronRightIcon color={colors.gray[8]}></ChevronRightIcon>}
              readOnly
            ></TextInputLabelUp>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApplyTransferAgencyServiceForm;
