import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import apiManager from '@/api/AxiosInstance';
import BasicFooterButton from '@/components/Common/Button/BasicFooterButton';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import BasicTabs from '@/components/Common/Tabs/BasicTabs';
import MenuHeader from '@/components/Header/MenuHeader';
import CarInfo from '@/components/Products/Sale/DetailTab/CarInfo';
import DetailInfo from '@/components/Products/Sale/DetailTab/DetailInfo';
import PhotoUploader from '@/components/Products/Sale/DetailTab/PhotoUploader';
import SalePrice from '@/components/Products/Sale/DetailTab/SalePrice';
import { colors } from '@/const/colors';
import { BEFORE_SALE, PAUSE, SALE } from '@/const/productStatus';
import { HASH_DETAIL, HASH_PHOTO, HASH_PRICE, HASH_PRODUCT_INFO } from '@/const/products';
import { phoneNumberPatternRegex } from '@/const/regex';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useNavigateBack from '@/hooks/useNavigateBack';
import NotFoundPage from '@/pages/Error/NotFoundPage';
import { isUnderFourTons } from '@/utils/common';

const MyCarDetailForm: React.FC = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const { showToast } = useToastContext();
  const [renderCount, setRenderCount] = useState(0);
  const productStatusRef = useRef<string>();
  const isClicked = useRef<boolean>();
  const [isDisabledSaveBtn, setIsDisabledSaveBtn] = useState(false);
  const [popupInfo, setPopupInfo] = useState<BasicPopupProps>({
    isShow: false,
    title: '',
    textContent: '',
    textRightBtn: '',
    onClickRightBtn: () => {},
    textLeftBtn: '',
    onClickLeftBtn: () => {},
  });
  const [isEtcManufacturerCategories, setIsEtcManufacturerCategories] = useState<boolean>();

  const [productInfo, setProductInfo] = useState<ProductDetailResponse>({
    id: 0,
    status: { code: '', desc: '' },
    manufacturerCategories: { id: 0, name: '', code: '' },
    model: { id: 0, name: '' },
    tons: '',
    color: { code: '', desc: '' },
    loaded: { code: '', desc: '' },
    loadedInnerArea: null,
    loadedInnerLength: null,
    loadedInnerHeight: null,
    axis: { code: '', desc: '' },
    transmission: { code: '', desc: '' },
    distance: 0,
    fuel: { code: 'DIESEL', desc: '디젤' },
    power: '',
    garage: { code: '', desc: '' },
    productsImage: {
      id: 0,
      certificateImageUrl: '',
      frontImageUrl: '',
      frontSideImageUrl: '',
      backImageUrl: '',
      backSideImageUrl: '',
      tireImageUrl: '',
      engineImageUrl: '',
      insideImageUrl: '',
      dashboardImageUrl: '',
      sheetImageUrl: '',
      engineVideoUrl: '',
      optionImageUrls: null,
    },
    accidentsHistory: {
      accident: false,
      accidentContents: '',
    },
    maintenance: { maintenanceData: [{ code: '', desc: '' }], etc: '' },
    tireStatus: null,
    transportStartLocate: { code: '', desc: '' },
    transportEndLocate: { code: '', desc: '' },
    transportGoods: '',
    carOption: {
      normalOption: { etc: '', option: [{ code: '', desc: '' }] },
      additionalOption: { etc: '', option: [{ code: '', desc: '' }] },
      breakOption: { etc: '', option: [{ code: '', desc: '' }] },
    },
    detailContent: '',
    price: null,
    actualSalePrice: null,
    sellerId: 0,
    sellerSafetyNumber: '',
  });
  const { id } = useParams();

  const getTabNum = () => {
    const hash = window.location.hash.substring(1);
    if (hash === HASH_PRODUCT_INFO) {
      return 0;
    } else if (hash === HASH_DETAIL) {
      return 1;
    } else if (hash === HASH_PHOTO) {
      return 2;
    } else if (hash === HASH_PRICE) {
      return 3;
    } else {
      return 0;
    }
  };

  const [tabNum, setTabNum] = useState(getTabNum());
  const [isShow, setIsShow] = useState(false);
  const [productType, setProductType] = useState<EnumPresenter>();
  const labelList: TabType[] = [
    { icon: null, title: '차량정보' },
    { icon: null, title: '상세정보' },
    { icon: null, title: '사진수정' },
    { icon: null, title: '판매가격' },
  ];
  const [isShowErrorPage, setIsShowErrorPage] = useState(false);
  const isTabNumFirst = tabNum === 0;

  const buttonList: Button[] = [
    {
      title: '이전',
      bgColor: colors.gray[0],
      borderColor: colors.gray[3],
      textColor: colors.gray[6],
      isDisabled: isTabNumFirst,
      onClick: () => {
        isTabNumFirst ? '' : onChangeTab(tabNum - 1);
      },
    },
  ];

  if (tabNum === labelList.length - 1) {
    // 마지막 탭일 때 '등록완료' 버튼 추가
    buttonList.push({
      title: '저장하기',
      bgColor: '',
      borderColor: 'transparent',
      isDisabled: false,
      onClick: () => {
        if (productInfo?.status.code === BEFORE_SALE) {
          handleRegistrationComplete(SALE, true);
        } else {
          handleRegistrationComplete(productInfo?.status.code, true);
        }
      },
    });
  } else {
    // 마지막 탭이 아닐 때 '다음' 버튼 추가
    buttonList.push({
      title: `다음(${labelList[tabNum + 1].title})`,
      bgColor: '',
      borderColor: 'transparent',
      isDisabled: false,
      onClick: () => onChangeTab(tabNum + 1),
    });
  }

  const isValidateChecked = () => {
    const {
      model,
      tons,
      color,
      loaded,
      loadedInnerArea,
      loadedInnerLength,
      loadedInnerHeight,
      axis,
      transmission,
      distance,
      fuel,
      power,
      garage,
      productsImage,
      detailContent,
      price,
    } = productInfo;

    const valuesToCheckCarInfo = [model, tons, loaded, loadedInnerLength, axis, distance, fuel, transmission, power];

    const valuesToCheckImage = [
      productsImage,
      productsImage?.frontSideImageUrl,
      productsImage?.backSideImageUrl,
      productsImage?.frontImageUrl,
    ];

    if (tabNum === 0 && valuesToCheckCarInfo.some((value) => value == null || value === '' || value === 0)) {
      showToast('필수값을 모두 입력해주세요.', 'error', 'bottom');
      isClicked.current = false;
      return false;
    } else if (tabNum === 2 && valuesToCheckImage.some((value) => value == null || value === '')) {
      showToast('필수값을 모두 입력해주세요.', 'error', 'bottom');
      isClicked.current = false;
      return false;
    } else if (tabNum === 1 && detailContent && isValidPhoneNumber(detailContent)) {
      setIsShow(true);
      setPopupInfo({
        isShow: isShow,
        title: '',
        textContent:
          '<span class="font-medium text-lg text-gray-8">개인 정보 보호를 위해 현재<br/>휴대폰 번호 입력은 제한되어 있습니다.</span>',
        textRightBtn: '확인',
        onClickRightBtn: () => {
          setIsShow(false);
        },
      });

      isClicked.current = false;
      return false;
    } else if (tabNum === 3 && (price === null || price === 0 || price === undefined)) {
      showToast('필수값을 모두 입력해주세요.', 'error', 'bottom');
      isClicked.current = false;
      return false;
    } else {
      return true;
    }
  };

  const handleRegistrationComplete = (status: string, isRegisterBtn: boolean) => {
    if (isClicked.current) {
      return;
    }

    const transformedCarOptionData = {
      normalOption: {
        etc: productInfo?.carOption?.normalOption?.etc || '',
        option: productInfo?.carOption?.normalOption?.option.map((item) => item.code) || [],
      },
      additionalOption: {
        etc: productInfo?.carOption?.additionalOption?.etc || '',
        option: productInfo?.carOption?.additionalOption?.option.map((item) => item.code) || [],
      },
      breakOption: {
        etc: productInfo?.carOption?.breakOption?.etc || '',
        option: productInfo?.carOption?.breakOption?.option.map((item) => item.code) || [],
      },
    };

    const transformedMaintenanceData = {
      maintenanceCategories: productInfo?.maintenance?.maintenanceData?.map((item) => item.code) || [],
      etc: productInfo?.maintenance?.etc || '',
    };

    const requestData: ProductModifyRequest = {
      accident: productInfo?.accidentsHistory?.accident || false,
      accidentContents: productInfo?.accidentsHistory?.accidentContents || '',
      axis: isUnderFourTons(productInfo?.tons) ? 'NONE' : productInfo?.axis?.code,
      backImageUrl: productInfo?.productsImage?.backImageUrl || '',
      backSideImageUrl: productInfo?.productsImage?.backSideImageUrl || '',
      carOption: transformedCarOptionData,
      certificateImageUrl: productInfo?.productsImage?.certificateImageUrl || '',
      color: productInfo?.color?.code,
      dashboardImageUrl: productInfo?.productsImage?.dashboardImageUrl || '',
      detailContent: productInfo?.detailContent || '',
      distance: productInfo?.distance,
      engineImageUrl: productInfo?.productsImage?.engineImageUrl || '',
      engineVideoUrl: productInfo?.productsImage?.engineVideoUrl || '',
      frontImageUrl: productInfo?.productsImage?.frontImageUrl || '',
      frontSideImageUrl: productInfo?.productsImage?.frontSideImageUrl || '',
      fuel: productInfo?.fuel?.code,
      garage: productInfo?.garage?.code,
      insideImageUrl: productInfo?.productsImage?.insideImageUrl || '',
      loaded: productInfo?.loaded?.code,
      loadedInnerArea: productInfo?.loadedInnerArea,
      loadedInnerHeight: productInfo?.loadedInnerHeight,
      loadedInnerLength: productInfo?.loadedInnerLength,
      maintenanceData: transformedMaintenanceData,
      modelId: productInfo?.model?.id,
      optionImageUrls: productInfo?.productsImage?.optionImageUrl || null,
      power: productInfo?.power || '',
      price: productInfo?.price,
      status: status,
      productsType: productType?.code,
      sheetImageUrl: productInfo?.productsImage?.sheetImageUrl || '',
      tireImageUrl: productInfo?.productsImage?.tireImageUrl || '',
      tireStatus: productInfo?.tireStatus?.code,
      tons: productInfo?.tons,
      transmission: productInfo?.transmission?.code,
      transportEndLocate: productInfo?.transportEndLocate?.code,
      transportGoods: productInfo?.transportGoods,
      transportStartLocate: productInfo?.transportStartLocate?.code,
    };
    isClicked.current = true;

    if (isRegisterBtn || status === SALE) {
      if (isValidateChecked()) {
        patchProducts(requestData, isRegisterBtn);
      }
    } else {
      patchProducts(requestData, isRegisterBtn);
    }
  };

  const isValidPhoneNumber = (content: string | undefined) => {
    if (!content) {
      return;
    }
    return phoneNumberPatternRegex.test(content);
  };

  const patchProducts = (requestData: ProductModifyRequest, isRegisterBtn: boolean) => {
    apiManager
      .patch(`/api/v2/products/${id}`, requestData)
      .then((response) => {
        const responseData: ProductDetailResponse = response.data;
        setProductInfo(responseData);
        if (isRegisterBtn) {
          navigate(`/products/${id}`, {
            state: { isRegisterBtn: productStatusRef.current === BEFORE_SALE },
            replace: true,
          });
        }
      })
      .catch((error) => {
        if (error.code === 'THREE_IMAGE_LEAST') {
          showToast(error.message, 'error', 'bottom');
          setTabNum(2);
        } else if (error.code === 'IMAGE_MUST_REQUIRED') {
          showToast('차량 등록증을 업로드해주세요.', 'error', 'bottom');
          setTabNum(2);
        } else if (error.code === 'MISSING_REQUEST_PARAMETER') {
          showToast('필수값을 모두 입력해주세요.', 'error', 'bottom');
          setTabNum(0);
        } else {
          showToast('문제가 생겼어요. 다시 시도해주세요', 'error', 'bottom');
        }
      })
      .finally(() => {
        isClicked.current = false;
      });
  };

  const goPrev = () => {
    setIsShow(true);
    setPopupInfo({
      isShow: isShow,
      title: '정말 나가시겠어요?',
      textContent: '작성 중인 내용은 임시저장되며<br/>언제든지 재등록이 가능합니다.',
      textLeftBtn: '나가기',
      onClickLeftBtn: () => {
        if (productInfo?.status.code === SALE) {
          handleRegistrationComplete(SALE, false);
        } else if (productInfo?.status.code === PAUSE) {
          handleRegistrationComplete(PAUSE, false);
        } else {
          handleRegistrationComplete(BEFORE_SALE, false);
        }
        setIsShow(false);
        navigateBack();
      },
      textRightBtn: '계속 등록',
      onClickRightBtn: () => {
        setIsShow(false);
      },
    });
  };

  const onChangeTab = (tabNum: number): boolean => {
    if (isValidateChecked()) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setTabNum(tabNum);
      let newHash = '';
      switch (tabNum) {
        case 0:
          newHash = HASH_PRODUCT_INFO;
          break;
        case 1:
          newHash = HASH_DETAIL;
          break;
        case 2:
          newHash = HASH_PHOTO;
          break;
        case 3:
          newHash = HASH_PRICE;
          break;
      }
      navigate(`#${newHash}`, { replace: true });
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (location.state) {
      setProductType(location.state.buttonKey);
    }
  }, [location.state]);

  useEffect(() => {
    if (renderCount > 0) {
      if (productInfo?.status.code === SALE) {
        handleRegistrationComplete(SALE, false);
      } else if (productInfo?.status?.code === PAUSE) {
        handleRegistrationComplete(PAUSE, false);
      } else {
        handleRegistrationComplete(BEFORE_SALE, false);
      }
    }
  }, [tabNum]);

  useEffect(() => {
    if (!location.state) {
      apiManager
        .get('/api/v1/products/' + id)
        .then((response) => {
          const responseData: ProductDetailResponse = response.data;
          setProductInfo(responseData);
          setIsEtcManufacturerCategories(responseData?.manufacturerCategories?.code === 'ETC');
          handleHashChange();
        })
        .catch(() => {
          setIsShowErrorPage(true);
        });
    } else {
      const data = location.state;
      setProductInfo(data);
    }
    setRenderCount(renderCount + 1);
  }, []);

  useEffect(() => {
    productStatusRef.current = productInfo?.status.code;
  }, [productInfo]);

  const updateDisabledBtn = (value: boolean) => {
    setIsDisabledSaveBtn(value);
  };

  const handleHashChange = () => {
    const hash = window.location.hash.substring(1);
    if (hash === HASH_PRODUCT_INFO) {
      setTabNum(0);
    } else if (hash === HASH_DETAIL) {
      setTabNum(1);
    } else if (hash === HASH_PHOTO) {
      setTabNum(2);
    } else if (hash === HASH_PRICE) {
      setTabNum(3);
    }
  };

  useEffect(() => {
    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <>
      {isShowErrorPage ? (
        <NotFoundPage></NotFoundPage>
      ) : (
        <>
          <MenuHeader
            title="차량 정보 수정"
            onClickPrevBtn={() => {
              goPrev();
            }}
          ></MenuHeader>

          <div className="pt-[110px] w-full">
            <div className="bg-white top-[60px] w-full  max-w-[720px] fixed z-10">
              <BasicTabs labelList={labelList} value={tabNum} onChange={onChangeTab} />
            </div>
            <div className="w-full px-4 pt-[30px] pb-[90px]">
              {tabNum === 0 && (
                <CarInfo
                  productInfo={productInfo}
                  setProductInfo={setProductInfo}
                  updateDisabledBtn={updateDisabledBtn}
                  isEtc={isEtcManufacturerCategories || false}
                ></CarInfo>
              )}
              {tabNum === 1 && <DetailInfo productInfo={productInfo} setProductInfo={setProductInfo}></DetailInfo>}
              {tabNum === 2 && (
                <PhotoUploader productInfo={productInfo} setProductInfo={setProductInfo}></PhotoUploader>
              )}
              {tabNum === 3 && <SalePrice productInfo={productInfo} setProductInfo={setProductInfo}></SalePrice>}
            </div>
            <BasicFooterButton
              text="저장"
              onClick={() => {
                if (productInfo?.status.code === BEFORE_SALE) {
                  handleRegistrationComplete(SALE, true);
                } else {
                  handleRegistrationComplete(productInfo?.status.code, true);
                }
              }}
              disabled={isDisabledSaveBtn}
            ></BasicFooterButton>
          </div>

          <BasicPopup
            isShow={isShow}
            title={popupInfo?.title}
            textContent={popupInfo?.textContent}
            textRightBtn={popupInfo.textRightBtn}
            onClickRightBtn={() => popupInfo.onClickRightBtn && popupInfo.onClickRightBtn()}
            textLeftBtn={popupInfo.textLeftBtn}
            onClickLeftBtn={() => popupInfo.onClickLeftBtn && popupInfo.onClickLeftBtn()}
          ></BasicPopup>
        </>
      )}
    </>
  );
};

export default MyCarDetailForm;
