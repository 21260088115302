import React from 'react';

import BasicButton from './BasicButton';
import { colors } from '@/const/colors';

export interface BasicFooterButton {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  shadow?: boolean;
}

const BasicFooterButton = ({ text, onClick, disabled = false, shadow = false }: BasicFooterButton) => {
  return (
    <div
      className={`flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-18 max-w-[720px] min-w-[280px] bg-white ${
        shadow ? 'shadow-[0_-2px_4px_rgba(0,0,0,0.05)]' : ''
      } z-30`}
    >
      <div
        className={`absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]`}
      ></div>
      <BasicButton
        name={text}
        bgColor={colors.primary}
        borderColor={colors.primary}
        textColor={colors.gray[0]}
        fontSize={16}
        height={48}
        fontWeight="bold"
        isDisabled={disabled}
        onClick={onClick}
      ></BasicButton>
    </div>
  );
};

export default BasicFooterButton;
