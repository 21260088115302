import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { UNAUTHORIZED_ACCESS } from '@/const/errorCode';
import { APPLICATION_JSON } from '@/const/headers';

const apiManager: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}`,
  timeout: 500000,
  headers: {
    'Content-Type': APPLICATION_JSON,
    'X-Requested-With': 'XMLHttpRequest',
  },
});

apiManager.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    if (config.url) {
      if (config.url.includes('refresh')) {
        config.withCredentials = true;
      } else if (!config.url.includes('login')) {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiManager.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errorResponse = error.response;

    if (errorResponse && errorResponse.data) {
      // 토큰이 만료되었을 때 처리
      if (errorResponse.status === 401) {
        if (errorResponse.data.code === 'JWT_TOKEN_EXPIRED' && !originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const response = await apiManager.post('/auth/refresh-token');
            const newAccessToken = response.data.accessToken;

            localStorage.setItem('token', newAccessToken);

            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

            return apiManager({
              ...originalRequest,
              headers: {
                ...originalRequest.headers, // 기존 헤더를 유지
                Authorization: `Bearer ${newAccessToken}`, // Authorization 헤더 업데이트
              },
            });
          } catch (refreshError) {
            localStorage.removeItem('token');
            window.location.replace('/login');
            return Promise.reject(refreshError);
          }
        } else if (
          errorResponse.data.code === 'REFRESH_TOKEN_EXPIRED' ||
          errorResponse.data.code === 'INVALID_REFRESH_TOKEN'
        ) {
          localStorage.removeItem('token');
          window.location.replace('/login');
        }
      }

      // accessToken, refreshToken 모두 만료시
      if (errorResponse.status === 401 && errorResponse.data.code === 'AUTHENTICATION_FAIL') {
        localStorage.removeItem('token');
        window.location.replace('/login');
      }

      // 권한 없음 처리
      if (errorResponse.status === 403 && errorResponse.data.code === UNAUTHORIZED_ACCESS) {
        window.location.replace('/unauthorized');
      }

      return Promise.reject(errorResponse.data);
    } else {
      return Promise.reject(errorResponse);
    }
  },
);

export default apiManager;
