import dayjs from 'dayjs';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { UseMutationOptions, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { createChatMessages } from '@/api/chat/createChat';
import { deleteChatRooms } from '@/api/chat/deleteChat';
import { fetchChatRooms } from '@/api/chat/getChat';
import ChatMessageContent from '@/components/Chat/ChatMessageContent';
import ChatRoomInput from '@/components/Chat/ChatRoomInput';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import BasicPopup from '@/components/Common/Popup/BasicPopup';
import ChatRoomHeader from '@/components/Header/ChatRoomHeader';
import { TEXT } from '@/const/chat';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { useMemberContext } from '@/contexts/Member/MemberContext';
import useNavigateBack from '@/hooks/useNavigateBack';
import useWebSocket from '@/hooks/useWebSocket';

const ChattingRoom = () => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { member } = useMemberContext();
  const { chatRoomId } = useParams();
  const [chatRoomData, setChatRoomData] = useState<ChattingDetailResponse>();
  const chatContentRef = useRef<HTMLDivElement>(null);
  const chatRoomHeaderRef = useRef<HTMLDivElement>(null);
  const [chatRoomHeaderHeight, setChatRoomHeaderHeight] = useState(0);
  const [message, setMessage] = useState<ChatMessage>();
  const [inputHeight, setInputHeight] = useState<number>(0);
  const [isShowPopup, setIsShowPopup] = useState<boolean>(false);
  const [chatInput, setChatInput] = useState('');
  const [imageList, setImageList] = useState<File[]>([]);
  const [readMessages, setReadMessages] = useState<ChatMessage[]>();

  const { showToast } = useToastContext();

  const id = chatRoomId || location.state?.chatRoomId;

  const { data, isLoading } = useQuery(['get-chat-rooms', id], () => fetchChatRooms(id), {
    onSuccess: (data) => {
      setChatRoomData(data);
      scrollBottom();
    },
    onError: () => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
    enabled: !!id,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (!id) {
      setChatRoomData(location.state?.chatRoomData);
    }
  }, [id, location.state?.chatRoomData]);

  const scrollBottom = () => {
    setTimeout(() => {
      if (chatContentRef.current) {
        window.scrollTo(0, chatContentRef.current.scrollHeight);
      }
    }, 10);
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollBottom();
    };

    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const handleInputHeightChange = useCallback((height: number) => {
    setInputHeight(height);
  }, []);

  const onClickOutChatroom = () => {
    setIsShowPopup(true);
  };

  const deleteChatRoomsMutation = useMutation((chatRoomId: string) => deleteChatRooms(chatRoomId), {
    onError: (error) => {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    },
  });

  const onClickDeleteChatRoom = () => {
    setIsShowPopup(false);

    if (chatRoomId) {
      deleteChatRoomsMutation.mutate(chatRoomId, {
        onSuccess: () => {
          showToast('채팅방을 나갔어요.', 'success', 'bottom');
          navigate('/chatting');
        },
      });
    } else if (id) {
      deleteChatRoomsMutation.mutate(id, {
        onSuccess: () => {
          showToast('채팅방을 나갔어요.', 'success', 'bottom');
          navigateBack();
        },
      });
    } else {
      showToast('채팅방을 나갔어요.', 'success', 'bottom');
      navigateBack();
    }
  };

  const updateChatRoomId = (id: number) => {
    if (chatRoomData && id) {
      setChatRoomData({ ...chatRoomData, id: id });
    }
  };

  const createChatMessageMutation = useMutation((request: ChatMessageRequest) => createChatMessages(request.formData), {
    onMutate: async (variable) => {
      if (member) {
        await queryClient.cancelQueries('get-chat-rooms');
        const tempId = variable.tempId ? variable.tempId : Date.now();
        const imagesArray: string[] = [];

        Array.from(imageList).forEach((file) => {
          const imageUrl = URL.createObjectURL(file);
          imagesArray.push(imageUrl);
        });

        const updateMessage = {
          id: tempId,
          tempId: tempId,
          chatRoomId: id,
          contents:
            variable.type === TEXT
              ? JSON.stringify({ text: variable.contents })
              : JSON.stringify({ images: variable.contents ? variable.contents : imagesArray }),
          type: { code: variable.type, desc: variable.type === TEXT ? '일반 텍스트 메시지' : '이미지 메시지' },
          senderId: member?.id,
          createdDate: dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss')?.toString(),
          isRead: false,
          isLoading: true,
        };

        setMessage(updateMessage as ChatMessage);
        setChatInput('');
        setImageList([]);

        return { updateMessage };
      }
    },
    onSuccess: (data, variables, context) => {
      setMessage((prev) => {
        if (prev?.id === context?.updateMessage.tempId) {
          return { ...data, senderId: member?.id, tempId: context?.updateMessage.tempId, isLoading: false };
        }
      });
    },
    onError: (error, variables, context) => {
      setMessage((prev) => {
        return { ...context?.updateMessage, isLoading: false } as ChatMessage;
      });
    },
  });

  const handleSendMessage = (
    data: ChatMessageRequest,
    options?: UseMutationOptions<ChatMessageData, unknown, ChatMessageRequest>,
  ) => {
    createChatMessageMutation.mutate(data, options);
  };

  const sendChat = () => {
    if (chatRoomData) {
      let type = '';
      const formData = new FormData();
      if (imageList.length > 0) {
        type = 'IMAGE';
        imageList.forEach((image, index) => {
          formData.append(`images`, image, `image${index}`);
        });
      } else {
        type = 'TEXT';
      }

      if (chatRoomData.id) {
        formData.append('chatRoomId', chatRoomData.id?.toString());
      } else {
        formData.append('productId', chatRoomData.productId?.toString());
      }
      formData.append('type', type);
      formData.append('contents', chatInput);

      const request = { formData: formData, type: type, contents: chatInput };

      handleSendMessage(request, {
        onSuccess: (data) => {
          if (chatRoomData.id) {
            return;
          } else if (data.chatRoom && data.chatRoom.id) {
            updateChatRoomId(data.chatRoom.id);
            navigate('/chatting/room', { state: { chatRoomId: data.chatRoom.id }, replace: true });
          }
        },
      });
    }
  };

  const updateChatInput = (data: string) => {
    setChatInput(data);
  };

  const updateImageList = (data: File[]) => {
    setImageList(data);
  };

  const isProductDeleted = () => chatRoomData?.isDeletedProduct;

  const socket = useWebSocket(`/sub/chat-room/${id}`, {
    onMessage: (message) => {
      try {
        const body = JSON.parse(message?.body);
        if (body.changeType === 'READ_CHAT_MESSAGES') {
          const messageData: ChatMessage[] = body.data;

          const transferedMessageData = messageData.map((msg) => ({
            ...msg,
            senderId: member?.id || 0,
          }));

          setReadMessages(transferedMessageData as ChatMessage[]);
        }
      } catch (error) {}
    },
    connectHeaders: {
      destination: `/sub/chat-room/${id}`,
      memberId: member ? String(member.id) : '',
      chatRoomId: id,
    },
    disconnectHeaders: {
      destination: `/sub/chat-room/${id}`,
      memberId: member ? String(member.id) : '',
      chatRoomId: id,
    },
  });

  useEffect(() => {
    return () => {
      if (socket) {
        try {
          socket.deactivate();
        } catch (error) {
          console.error('Error disconnecting WebSocket:', error);
        }
      }
    };
  }, [socket]);

  useEffect(() => {
    setChatRoomHeaderHeight(chatRoomHeaderRef.current?.clientHeight || 0);
  }, [chatRoomHeaderRef.current?.clientHeight]);
  return (
    <>
      {!!chatRoomData && (
        <div ref={chatContentRef} className="w-full h-100dvh flex flex-col justify-center items-center overflow-hidden">
          <ChatRoomHeader
            ref={chatRoomHeaderRef}
            chatRoomData={chatRoomData}
            onClickDeleteBtn={onClickOutChatroom}
          ></ChatRoomHeader>
          <div
            className={`flex-1 w-full grow overflow-y-auto flex-grow-1 `}
            style={{
              marginBottom: `calc(${inputHeight}px)`,
              marginTop: `${
                isProductDeleted() ? `calc(${chatRoomHeaderHeight}px)` : `calc(${chatRoomHeaderHeight}px)`
              }`,
            }}
          >
            <ChatMessageContent
              message={message}
              chatRoomData={chatRoomData}
              scrollBottom={scrollBottom}
              updateChatRoomId={updateChatRoomId}
              handleSendMessage={handleSendMessage}
              readMessages={readMessages}
            ></ChatMessageContent>
          </div>
          <ChatRoomInput
            chatInput={chatInput}
            updateChatInput={updateChatInput}
            handleInputHeightChange={handleInputHeightChange}
            sendChat={sendChat}
            imageList={imageList}
            updateImageList={updateImageList}
          ></ChatRoomInput>
        </div>
      )}
      <BasicPopup
        isShow={isShowPopup}
        title="채팅방 나가기"
        textContent="채팅방을 나가면 대화 내용 및 계약서가 <br/>삭제되고 복구할 수 없습니다.<br/>채팅방에서 나가시겠어요?"
        textLeftBtn="취소"
        onClickLeftBtn={() => setIsShowPopup(false)}
        textRightBtn="나가기"
        onClickRightBtn={onClickDeleteChatRoom}
      ></BasicPopup>
      {isLoading && <LoadingSpinner text={'로딩중입니다.'}></LoadingSpinner>}
    </>
  );
};

export default ChattingRoom;
