import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Error from '@/components/Error/Error';
import MenuHeader from '@/components/Header/MenuHeader';
import { ErrorCircleIcon } from '@/components/Icon';
import MyCarNumberInput from '@/components/Products/Sale/MyCarNumberInput';
import OneStopServiceRequestButton from '@/components/Products/Sale/OneStopServiceRequestButton';
import OwnerNameInput from '@/components/Products/Sale/OwnerNameInput';
import { colors } from '@/const/colors/index';
import { SALESTYPE } from '@/const/products';
import useNavigateBack from '@/hooks/useNavigateBack';
import metadata from '@/metadatas/metadata';
import { PageMetas } from '@/metadatas/metadatas';
import { useResetProductsForm } from '@/store/products';

interface ErrorInfo {
  licenseNumberError: boolean;
  licenseNumberErrorMessage: string;
  ownerNameError: boolean;
  ownerNameErrorMessage: string;
}

type SalesTypeKey = keyof typeof SALESTYPE;

const MyCarSale = () => {
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const [salesType, setSalesType] = useState<String>();

  useEffect(() => {
    if (location.state?.type) {
      setSalesType(location.state?.type);
    }
  }, []);

  const [ownerInfo, setOwnerInfo] = useState<OwnerInfo>({
    licenseNumber: '',
    name: '',
  });

  const resetProductsForm = useResetProductsForm();
  const [errorInfo, setErrorInfo] = useState<ErrorInfo>();
  const [pageNum, setPageNum] = useState(1);

  const updatePage = (pageNum: number) => {
    setPageNum(pageNum);
  };
  const goPrev = () => {
    if (pageNum === 1) {
      navigateBack();
    } else {
      setPageNum((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (location.state?.pageNum) {
      updatePage(location.state.pageNum);
    }
  }, [pageNum]);

  useEffect(() => {
    resetProductsForm();
  }, []);

  return (
    <>
      <PageMetas {...metadata.sale}></PageMetas>
      <div className="flex flex-col justify-between w-full">
        <>
          <MenuHeader
            title={SALESTYPE[location.state?.type as SalesTypeKey]}
            onClickPrevBtn={() => {
              goPrev();
            }}
          ></MenuHeader>
          <div>
            {pageNum === 1 && (
              <div className="pt-[60px] pb-20">
                <MyCarNumberInput
                  updatePage={updatePage}
                  ownerInfo={ownerInfo}
                  setOwnerInfo={setOwnerInfo}
                  errorInfo={errorInfo}
                  setErrorInfo={setErrorInfo}
                ></MyCarNumberInput>
                <OneStopServiceRequestButton />
              </div>
            )}
            {pageNum === 2 && (
              <div className="pb-20">
                <OwnerNameInput
                  updatePage={updatePage}
                  ownerInfo={ownerInfo}
                  setOwnerInfo={setOwnerInfo}
                  errorInfo={errorInfo}
                  setErrorInfo={setErrorInfo}
                  salesType={salesType}
                ></OwnerNameInput>
              </div>
            )}
            {pageNum === 3 && (
              <div className="h-screen">
                <Error
                  icon={<ErrorCircleIcon color={colors.gray[5]} width={144} height={144}></ErrorCircleIcon>}
                  title="오류가 발생했습니다"
                  description="현재 일시적인 시스템 오류로 <br/> 차량 정보를 불러올 수 없습니다. <br/>잠시 후 다시 시도해주세요."
                ></Error>
              </div>
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default MyCarSale;
