import React from 'react';

export interface IconInfoButton {
  onClick: () => void;
  icon: React.ReactNode;
  title: string;
}

const IconInfoButton = ({ onClick, icon, title }: IconInfoButton) => (
  <section
    className="sm280:flex sm280:justify-between sm680:flex sm680:justify-between cursor-pointer bg-gray-1 rounded-[12px] p-[18px] w-full"
    onClick={onClick}
  >
    <div
      className="text-gray-8 font-bold text-[18px] leading-[22px] break-keep"
      dangerouslySetInnerHTML={{ __html: title }}
    ></div>
    <div className={`flex justify-end items-center`}>{icon}</div>
  </section>
);

export default IconInfoButton;
