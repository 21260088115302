import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { fetchChatRoomList } from '@/api/chat/getChat';
import BasicButton from '@/components/Common/Button/BasicButton';
import PurchaseAccompanyingServiceChatListSkeleton from '@/components/Common/Skeleton/PurchaseAccompanyingServiceChatListSkeleton';
import MenuHeader from '@/components/Header/MenuHeader';
import { CheckCircleOutlineIcon, CheckCircleSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { COMMON_TOAST_ERROR } from '@/const/errorMessage';
import { useToastContext } from '@/contexts/Common/ToastContext';
import useNavigateBack from '@/hooks/useNavigateBack';
import { formatPrice } from '@/utils/common';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

const SelectVehicleFromChatList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateBack = useNavigateBack();
  const { showToast } = useToastContext();

  const pathName = location.pathname;
  const chatRoomId = location.state.chatRoomId;

  const [, serviceType] = pathName.split('/');

  const [selectedChatRoomId, setSelectedChatRoomId] = useState<number | null>(chatRoomId);
  const [chattingList, setChattingList] = useState<ChattingListResponse[]>([]);

  const { data, isLoading, isFetching } = useQuery<ChattingListResponse[]>(
    ['get-chat-room-list'],
    () => fetchChatRoomList(),
    {
      onSuccess: (res) => {
        setChattingList(res);
      },
      onError: () => {
        showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      // enabled: !isTransferAgencyService,
    },
  );

  const handleChangeRadio = (item: ChattingListResponse) => {
    setSelectedChatRoomId((prev) => (prev === item.chatRoomId ? null : item.chatRoomId));
  };

  const handleClickSelectVehicle = () => {
    const selectedItem = chattingList?.find((data: ChattingListResponse) => data?.chatRoomId === selectedChatRoomId);

    navigate(`/${serviceType}`, {
      state: {
        productInfo: {
          productId: selectedItem?.productId,
          truckName: selectedItem?.truckName,
          chatRoomId: selectedItem?.chatRoomId,
        },
        productPrice: selectedItem?.price,
        isScrollBottom: true,
      },
    });
  };

  const handleClickGoToProductList = () => {
    navigate('/products');
  };

  const getGuideInfo = () => {
    switch (serviceType) {
      case 'capital-counsel-service':
        return {
          headerTitle: '화물차 대출 상담 서비스',
          title: '상담을 위해 차량을 선택해주세요.',
          content:
            '채팅 목록에서 상담받을 차량을 선택해주세요.<br/>차량 선택 후 서비스 신청 시 상담이 더 정확하고 빠르게 진행됩니다.',
        };

      case 'purchase-accompanying-service':
        return {
          headerTitle: '구매 동행 서비스',
          title: '구매를 원하는 차량을 선택해주세요.',
          content: '채팅 목록에서 차량 구매 대행 서비스를 이용하기 위한 차량을 선택해주세요.',
        };

      case 'transfer-agency-service':
        return {
          headerTitle: '서류 이전 대행 서비스',
          title: '서류 이전 대행 서비스를 신청할 차량을 선택해주세요.',
          content: '채팅 목록에서 서류 이전 대행 서비스를 이용하기 위한 차량을 선택해주세요.',
        };
      default:
        return {
          title: '',
          content: '',
        };
    }
  };

  const handleClickPrev = () => {
    navigateBack();
  };

  const getMessageText = (message: ChattingListResponse) => {
    const lastMessage = JSON.parse(message?.lastMessage);
    return !!message && lastMessage?.text
      ? lastMessage?.text
      : !!message && lastMessage?.images
      ? '사진을 보냈습니다.'
      : '';
  };

  return (
    <>
      <MenuHeader title={getGuideInfo()?.headerTitle || ''} onClickPrevBtn={handleClickPrev}></MenuHeader>
      <div className="w-full pt-[60px] pb-16">
        <div className="bg-blue-0 rounded-[10px] px-4 py-[19px] mx-4">
          <p className="font-bold text-gray-8">{getGuideInfo().title}</p>
          <div
            className="text-[14px] mt-4 leading-[18px] text-gray-8"
            dangerouslySetInnerHTML={{ __html: getGuideInfo().content }}
          ></div>
        </div>
        <div className="px-4 py-6">
          {isLoading || isFetching ? (
            <PurchaseAccompanyingServiceChatListSkeleton></PurchaseAccompanyingServiceChatListSkeleton>
          ) : chattingList && chattingList?.length > 0 ? (
            <RadioGroup
              value={selectedChatRoomId || ''}
              name={`radio-buttons-group`}
              sx={{ display: 'flex', gap: '12px' }}
            >
              {chattingList.map((item: ChattingListResponse, index: number) => (
                <div
                  key={index}
                  className={`border cursor-pointer rounded-[12px] p-[14px] w-full ${
                    item?.chatRoomId === selectedChatRoomId ? 'border-primary bg-blue-1' : 'border-gray-3'
                  }`}
                  onClick={(e) => handleChangeRadio(item)}
                >
                  <FormControlLabel
                    value={item?.chatRoomId}
                    control={
                      <Radio
                        icon={<CheckCircleOutlineIcon color={colors.gray[3]} />}
                        checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                        sx={{
                          borderWidth: '4px',
                          fontSize: '14px',
                          borderRadius: '10px',
                          paddingRight: '6px',
                        }}
                      />
                    }
                    onClick={(e) => e.preventDefault()}
                    sx={{ width: '100%' }}
                    checked={item?.chatRoomId === selectedChatRoomId}
                    label={
                      <div className="flex justify-between items-center">
                        <dl className="flex flex-col justify-center mx-3 flex-1">
                          <dt className="flex flex-col justify-center">
                            <div className="font-semibold py-1 text-gray-8 mb-1 text-[14px] leading-[16px]">
                              {item.truckName}
                            </div>
                            <div className="text-gray-7 font-medium text-[12px] mb-[2px] leading-[12px]">
                              {item.memberName} [{item.truckNumber}]
                            </div>
                          </dt>

                          {serviceType === 'transfer-agency-service' ? (
                            <dd
                              className={`flex-1 max-w-[500px] text-gray-6 font-medium text-[12px] line-clamp-2 text-ellipsis break-all pr-8 leading-[16px]`}
                            >
                              {getMessageText(item)}
                            </dd>
                          ) : (
                            <dd
                              className={`flex-1 max-w-[500px] text-gray-8 font-semibold text-[14px] leading-[18px] pr-8 mt-1`}
                            >
                              {formatPrice(String(item.price))}
                            </dd>
                          )}
                        </dl>
                        <div className="rounded-[12px] w-12 h-12 min-w-[48px] mx-2 my-1">
                          {item.productRepresentImageUrl && (
                            <img
                              src={item?.productRepresentImageUrl}
                              alt="profile-img"
                              className="rounded-lg w-full h-full object-cover"
                            />
                          )}
                        </div>
                      </div>
                    }
                    componentsProps={{
                      typography: {
                        sx: {
                          width: '100%',
                        },
                      },
                    }}
                  />
                </div>
              ))}
            </RadioGroup>
          ) : (
            <>
              <div className="w-full h-full px-4 " style={{ height: `calc(100vh - 300px)` }}>
                <div className="flex flex-col justify-center items-center w-full h-full text-center">
                  <p className="text-gray-7 text-[18px] mt-4 text-center mb-[30px]">채팅 내역이 없습니다.</p>
                  <BasicButton
                    name="구매할 차량 둘러보기"
                    bgColor={colors.primary}
                    borderColor={colors.primary}
                    textColor={colors.gray[0]}
                    fontSize={16}
                    width={230}
                    height={48}
                    borderRadius={12}
                    fontWeight="semibold"
                    onClick={handleClickGoToProductList}
                  ></BasicButton>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="flex flex-col fixed w-full mx-auto my-0 bottom-0 left-0 right-0 h-16 max-w-[720px] min-w-[280px]">
          <div className="flex gap-2 bg-white px-3 py-2">
            <BasicButton
              name="건너뛰기"
              bgColor={colors.gray[0]}
              borderColor={colors.gray[3]}
              textColor={colors.gray[6]}
              fontSize={16}
              height={48}
              fontWeight="bold"
              onClick={() => handleClickPrev()}
            ></BasicButton>
            <BasicButton
              name="차량 선택하기"
              bgColor={colors.primary}
              borderColor={colors.primary}
              textColor={colors.gray[0]}
              fontSize={16}
              height={48}
              fontWeight="bold"
              isDisabled={!selectedChatRoomId}
              onClick={handleClickSelectVehicle}
            ></BasicButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectVehicleFromChatList;
