import React, { lazy, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ImageModal from '@/components/Common/ImageModal';
import MenuHeader from '@/components/Header/MenuHeader';
import NotFoundPage from '@/pages/Error/NotFoundPage';

const TransformComponent = lazy(() =>
  import('react-zoom-pan-pinch').then((module) => ({ default: module.TransformComponent })),
);
const TransformWrapper = lazy(() =>
  import('react-zoom-pan-pinch').then((module) => ({ default: module.TransformWrapper })),
);

const ProductImageDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isShow, setIsShow] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const title = location.state?.title;
  const imageList = location.state?.imageList;

  const handleClickImg = (item: string) => {
    setIsShow(true);
    setImageUrl(item);
  };

  return (
    <>
      {imageList && imageList.length > 0 ? (
        <>
          <MenuHeader title={title || ''} onClickPrevBtn={() => navigate(-1)} />
          <div className="w-full h-screen bg-gray-0 top-0 max-w-[720px] pb-[24px]">
            <div className="relative max-h-[800px] max-w-[720px] mt-[60px]">
              {imageList?.map((item: string, index: React.Key | null | undefined) => (
                <div key={index}>
                  <div
                    className="flex flex-col w-full overflow-auto"
                    style={{
                      width: '100%',
                    }}
                    onClick={() => handleClickImg(item)}
                  >
                    <div className="w-full flex flex-col justify-center items-center overflow-hidden">
                      <img src={item} alt="이미지" className="w-full object-center" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <ImageModal isShow={isShow} onClose={() => setIsShow(false)} img={imageUrl} />
        </>
      ) : (
        <NotFoundPage />
      )}
    </>
  );
};

export default ProductImageDetail;
