import { useEffect, useState } from 'react';

import { formatValueToDecimalPlaces } from '@/utils/common';

interface LoanCalculatorState {
  principal: string;
  loanTerm: string;
  interestRate: string;
  monthlyPayment: string;
}

export const useLoanCalculator = (initialPrice: number, initialInterestRate = '7.6', initialLoanTerm = '60') => {
  const [loanCalculatorState, setLoanCalculatorState] = useState<LoanCalculatorState>({
    principal: initialPrice ? String(initialPrice * 10000) : '0',
    loanTerm: initialLoanTerm,
    interestRate: initialInterestRate,
    monthlyPayment: '0',
  });

  const handleInputChange = (name: string, value: string) => {
    const nonNumericOrDecimalPattern = /[^0-9.]/g;
    const convertedValue = value.replace(nonNumericOrDecimalPattern, '');

    if (convertedValue.startsWith('.')) return;

    const formattedValue = formatValueToDecimalPlaces(convertedValue, 2);

    setLoanCalculatorState((prevState) => ({
      ...prevState,
      [name]: name === 'interestRate' ? formattedValue : convertedValue,
    }));
  };

  const handleInputBlur = (name: keyof LoanCalculatorState) => {
    setLoanCalculatorState((prevState) => {
      const updatedValue = prevState[name].replace(/^0+(?=\d)/, '');

      return {
        ...prevState,
        [name]: updatedValue || '0',
      };
    });
  };

  const calculateMonthlyPayment = () => {
    const { principal, loanTerm, interestRate } = loanCalculatorState;

    if (!!principal && !!loanTerm) {
      const loanAmount = Number(principal);
      const term = Number(loanTerm);
      let payment = 0;

      if (interestRate && Number(interestRate) > 0) {
        const monthlyInterestRate = Number(interestRate) / 12 / 100;
        payment = loanAmount * (monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -term)));
      } else {
        payment = loanAmount / term;
      }

      setLoanCalculatorState((prevState) => ({
        ...prevState,
        monthlyPayment: payment ? payment.toFixed(0) : '0',
      }));
    } else {
      setLoanCalculatorState((prevState) => ({
        ...prevState,
        monthlyPayment: '0',
      }));
    }
  };

  useEffect(() => {
    calculateMonthlyPayment();
  }, [loanCalculatorState.principal, loanCalculatorState.loanTerm, loanCalculatorState.interestRate]);

  return {
    loanCalculatorState,
    handleInputChange,
    handleInputBlur,
    setLoanCalculatorState,
  };
};
