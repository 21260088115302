import React, { useState } from 'react';

import DynamicWidthInput from '@/components/Common/Input/DynamicWidthInput';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { useLoanCalculator } from '@/hooks/useLoanCalculator';
import { formatNumber } from '@/utils/common';
import { Input } from '@mui/material';

interface CapitalCounselServiceProps {
  price: number;
}

const loanTermsOption = ['12', '24', '36', '46', '60'];

const CapitalGuideView = ({ price }: CapitalCounselServiceProps) => {
  const { loanCalculatorState, handleInputChange, handleInputBlur, setLoanCalculatorState } = useLoanCalculator(price);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const handleOnClickLoanTerm = () => {
    setIsOpenPopup(true);
  };

  const handleClosePopup = () => {
    setIsOpenPopup(false);
  };

  const handleClickOption = (option: string) => {
    setLoanCalculatorState({ ...loanCalculatorState, loanTerm: option });
    handleClosePopup();
  };

  return (
    <>
      <div className="flex flex-col justify-center items-center bg-gradient-to-b from-[#C4EAC4] to-[#8FCE8E] py-10 px-4">
        <div className="flex sm680:flex-row sm680:gap-6 flex-col items-center justify-center">
          <div>
            <p className="text-[28px] font-bold text-center text-[#315230]">
              중고화물차
              <br className="sm280:block hidden" /> 저금리 대출
            </p>
            <div className="grid grid-cols-2 gap-2 text-center mt-6 mb-4">
              <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
                차량대금 <br className="sm280:block hidden" />
                <span className="font-bold">전액 가능</span>
              </div>
              <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
                무방문, <br className="sm280:block hidden" />
                <span className="font-bold">간편서류 접수</span>
              </div>
              <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
                신용점수 <br className="sm280:block hidden" />
                <span className="font-bold">영향 NO</span>
              </div>
              <div className="bg-gray-0 opacity-80 rounded-[40px] px-[18px] py-[6px] text-gray-8 text-[12px] font-medium">
                개인맞춤 <br className="sm280:block hidden" />
                <span className="font-bold">대출상품 상담</span>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center max-w-[248px] w-[248px] h-[196px]">
            <img
              src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/capital_counsel_service1.png"
              alt="구매 동행 서비스 트럭 이미지"
              className="w-full"
            />
          </div>
        </div>

        <div className="p-6 rounded-[12px] bg-gray-0 opacity-90 min-h-[204px] w-auto text-center mt-4 text-[18px]">
          <DynamicWidthInput
            name="principal"
            placeholder=""
            value={loanCalculatorState.principal ? formatNumber(loanCalculatorState.principal, true) : ''}
            endAdornment="원"
            onChange={(e) => handleInputChange('principal', e.target.value)}
            onBlur={() => handleInputBlur('principal')}
            maxLength={11}
          />
          &nbsp;&nbsp; 차량에 &nbsp;&nbsp;
          <br className="sm550:hidden sm680:hidden" />
          할부 기간&nbsp;&nbsp;
          <Input
            name="loanTerm"
            value={loanCalculatorState?.loanTerm || ''}
            endAdornment={
              <div className="flex min-w-[65px]">
                <span className="mr-1">개월</span>
                <ChevronDownIcon color={colors.gray[8]} />
              </div>
            }
            sx={{
              width: '90px',
              marginBottom: '6px',
              fontSize: '18px',
            }}
            readOnly
            onChange={(e) => handleInputChange('loanTerm', e.target.value)}
            onClick={handleOnClickLoanTerm}
          />
          동안 <br />
          <DynamicWidthInput
            name="interestRate"
            placeholder=""
            value={String(loanCalculatorState?.interestRate) || ''}
            endAdornment="%"
            onChange={(e) => handleInputChange('interestRate', e.target.value)}
            onBlur={() => handleInputBlur('interestRate')}
            maxLength={5}
          />{' '}
          금리 적용 시<div className="text-gray-7 text-[10px] mt-3">※ 최저 금리 7.6% 기준</div>
          <div className="text-[#315230] font-semibold text-[30px] mt-6">
            월 {formatNumber(loanCalculatorState.monthlyPayment)}원
          </div>
        </div>
      </div>
      <DownToUpDetailPopup isShow={isOpenPopup} onClosePopup={handleClosePopup} title={'할부 기간'} isBackdrop={true}>
        <div className="p-4">
          {loanTermsOption.map((option, index) => {
            return (
              <div key={index} className="h-[52px] py-4 cusor-pointer" onClick={() => handleClickOption(option)}>
                {option}개월
              </div>
            );
          })}
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default CapitalGuideView;
