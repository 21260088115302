import React from 'react';

import TextInputLabelLeft from '@/components/Common/Input/TextInputLabelLeft';
import { useLoanCalculator } from '@/hooks/useLoanCalculator';
import { formatNumber } from '@/utils/common';

interface LoanCalculatorProps {
  price: number;
}

const LoanCalculator = ({ price }: LoanCalculatorProps) => {
  const { loanCalculatorState, handleInputChange, handleInputBlur, setLoanCalculatorState } = useLoanCalculator(price);

  return (
    <>
      <div className="flex flex-col gap-4">
        <TextInputLabelLeft
          name="principal"
          label="대출 금액"
          placeholder="예시) 50,000,000"
          type="text"
          value={loanCalculatorState.principal ? formatNumber(loanCalculatorState.principal, true) : ''}
          onChange={(e) => handleInputChange('principal', e.target.value)}
          inputMode="numeric"
          suffix="원"
          maxLength={11}
        />
        <TextInputLabelLeft
          name="loanTerm"
          label="할부 기간"
          placeholder="예시) 60"
          value={loanCalculatorState?.loanTerm || ''}
          onChange={(e) => handleInputChange('loanTerm', e.target.value)}
          inputMode="numeric"
          suffix="개월"
          maxLength={2}
        />
        <TextInputLabelLeft
          name="interestRate"
          label="할부 금리"
          placeholder="예시) 7.6"
          value={loanCalculatorState?.interestRate || ''}
          onChange={(e) => handleInputChange('interestRate', e.target.value)}
          inputMode="numeric"
          suffix="%"
          maxLength={5}
        />
        <div className="flex justify-between items-center font-semibold py-4">
          <p className="text-gray-8">월 할부금</p>
          <p className="text-lg text-gray-9">{formatNumber(loanCalculatorState.monthlyPayment)}원</p>
        </div>
      </div>
    </>
  );
};

export default LoanCalculator;
