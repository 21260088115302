import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { getInterestProductsNotificationSettings } from '@/api/public';
import BasicButton from '@/components/Common/Button/BasicButton';
import InterestProductNotificationSkeleton from '@/components/Common/Skeleton/InterestProductNotificationListSkeleton';
import MenuHeader, { MenuHeaderIcon } from '@/components/Header/MenuHeader';
import { PlusSmallIcon } from '@/components/Icon';
import InterestProductCard from '@/components/Notification/InterestProductCard';
import { colors } from '@/const/colors';
import CommonError from '@/pages/Error/CommonError';

const MIN_LOADING_TIME = 200;

const NotificationProductList = () => {
  const navigate = useNavigate();
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const getMyInterestProducts = async () => {
    try {
      const response = await getInterestProductsNotificationSettings();
      const res: InterestProducts[] = response.data;
      return res;
    } catch (error) {
      throw new Error('Error');
    }
  };

  const { data, isError, error, isFetching, isLoading, isSuccess, refetch } = useQuery(
    ['get-interest-products'],
    getMyInterestProducts,
    {
      onSettled: () => {
        setIsDataFetched(true);
      },
    },
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(true);
    }, MIN_LOADING_TIME);

    return () => clearTimeout(timer);
  }, []);

  if (isError)
    return (
      <>
        <CommonError></CommonError>
      </>
    );

  const menuHeaderIcons: MenuHeaderIcon[] = [
    {
      icon: (
        <div className="flex justify-center items-center w-5 h-5 cursor-pointer border border-gray-6 rounded-[6px]">
          <PlusSmallIcon color={colors.gray[6]} width={24} height={24}></PlusSmallIcon>
        </div>
      ),
      onClickIcon: () => navigate('/notification/products/form'),
    },
  ];

  return (
    <>
      <MenuHeader title="관심 차량 알림 설정" icons={data?.length === 0 ? [] : menuHeaderIcons}></MenuHeader>
      <div className="pt-[60px] pb-8 w-full">
        {!isDataFetched ? (
          showSkeleton ? (
            <InterestProductNotificationSkeleton></InterestProductNotificationSkeleton>
          ) : null
        ) : data?.length === 0 ? (
          <>
            <div className="w-full h-full px-4 mt-[48px]" style={{ height: `calc(100vh - 180px)` }}>
              <div className="flex flex-col justify-center items-center w-full h-full">
                <p className="text-gray-7 text-[18px] leading-[22px] mt-4 text-center mb-[30px]">
                  설정된 관심 차량이 없습니다.
                  <br />
                  관심 차량을 설정해주세요.
                </p>
                <BasicButton
                  name="관심 차량 등록"
                  bgColor={colors.primary}
                  borderColor={colors.primary}
                  textColor={colors.gray[0]}
                  fontSize={16}
                  width={230}
                  height={48}
                  borderRadius={12}
                  fontWeight="semibold"
                  onClick={() => navigate('/notification/products/form')}
                ></BasicButton>
              </div>
            </div>
          </>
        ) : (
          <>
            {data?.map((item, index) => (
              <div key={item.id} className="m-4">
                <InterestProductCard data={item} refetch={refetch}></InterestProductCard>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};
export default NotificationProductList;
