import React, { useEffect, useRef, useState } from 'react';

import { Input } from '@mui/material';

const DynamicWidthInput = ({
  name,
  value,
  placeholder,
  onChange,
  onBlur,
  endAdornment,
  maxLength,
  ...props
}: {
  name: string;
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  endAdornment?: React.ReactNode;
  maxLength?: number;
}) => {
  const inputRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState(placeholder.length * 10);

  useEffect(() => {
    if (inputRef.current) {
      const calculatedWidth = inputRef.current.offsetWidth + 20;
      setWidth(calculatedWidth);
    }
  }, [value]);

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <span
        ref={inputRef}
        style={{
          position: 'absolute',
          visibility: 'hidden',
          whiteSpace: 'nowrap',
          fontSize: '18px',
          fontFamily: 'inherit',
        }}
      >
        {value || placeholder}
      </span>
      <Input
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        endAdornment={endAdornment}
        sx={{ width: `${width}px`, transition: 'width 0.2s', fontSize: '18px' }}
        inputProps={{ maxLength }}
        {...props}
      />
    </div>
  );
};

export default DynamicWidthInput;
