import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import PhoneAuth from '@/components/Common/PhoneAuth';
import MenuHeader from '@/components/Header/MenuHeader';
import { CheckCircleOutlineIcon, CheckCircleSolidIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { Checkbox, Divider } from '@mui/material';

interface SignUpProps {
  memberInfo: MemberInfo;
  updateMemberInfo: (data: MemberInfo) => void;
  isFirst: boolean;
  setIsFirst: React.Dispatch<React.SetStateAction<boolean>>;
}

const PhoneAuthAndTerms = ({ memberInfo, updateMemberInfo, isFirst, setIsFirst }: SignUpProps) => {
  const navigate = useNavigate();
  const [checkList, setCheckList] = useState<string[]>([]);

  const agreementList = [
    { id: 'terms', title: '이용 약관', isRequired: true, link: '#', type: 'USE_TERMS' },
    {
      id: 'personalInfo',
      title: '개인정보 수집 및 이용 동의',
      isRequired: true,
      link: '#',
      type: 'PERSONAL_INFO_TERMS',
    },
    { id: 'marketing', title: '마케팅 정보 수신 및 활용 동의', isRequired: false, link: '#', type: 'MARKETING' },
  ];

  useEffect(() => {
    const conditions: { [key: string]: string } = {
      terms: 'terms',
      personalInfo: 'personalInfo',
      marketing: 'marketing',
    };

    const newCheckList = Object.keys(conditions)
      .filter((key) => memberInfo[key])
      .map((key) => conditions[key]);

    setCheckList(newCheckList);
  }, [memberInfo]);

  const isSuccess = useMemo(() => {
    const requiredIds = agreementList.filter((el) => el.isRequired).map((el) => el.id);
    const allRequiredAgreed = requiredIds.every((id) => checkList.includes(id));
    return memberInfo.isAuthenticateCode && allRequiredAgreed;
  }, [memberInfo.isAuthenticateCode, checkList]);

  const goNext = () => {
    updateMemberInfo({
      ...memberInfo,
      marketing: checkList.includes('marketing'),
      terms: checkList.includes('terms'),
      personalInfo: checkList.includes('personalInfo'),
    });
    setIsFirst(false);
  };

  const handleSingleCheck = (checked: boolean, id: string) => {
    updateMemberInfo({
      ...memberInfo,
      [id]: checked,
    });
  };

  const handleCheckAll = (checked: boolean) => {
    updateMemberInfo({
      ...memberInfo,
      marketing: checked,
      terms: checked,
      personalInfo: checked,
    });
  };

  const onClickTermDetail = (type: string) => {
    navigate(`/terms/${type}`);
  };

  return (
    <>
      <MenuHeader title="전화번호로 회원가입"></MenuHeader>
      <div className="flex flex-col justify-between w-full pt-4 px-4 h-[100dvh]">
        <div>
          <p className="text-[22px] font-bold mt-6 mb-[30px] pt-6 text-gray-8">휴대폰 번호를 인증해주세요</p>
          <PhoneAuth
            isSign={true}
            memberInfo={memberInfo}
            updateMemberInfo={(data) => updateMemberInfo(data)}
          ></PhoneAuth>

          <section className="mt-[70px] pb-8">
            <div className="text-sm pb-[3px]">
              <Checkbox
                icon={<CheckCircleOutlineIcon color={colors.gray[4]} />}
                checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                sx={{
                  borderWidth: '4px',
                  color: colors.gray[4],
                  '&.Mui-checked': {
                    color: colors.primary,
                  },
                }}
                onChange={(e) => handleCheckAll(e.target.checked)}
                checked={checkList.length === agreementList.length}
              />
              <span
                className="text-gray-8 font-medium"
                onClick={() => handleCheckAll(checkList.length !== agreementList.length)}
              >
                서비스 이용 약관 전체 동의하기
              </span>
            </div>

            <Divider></Divider>

            {agreementList?.map((item, index) => (
              <div key={index}>
                <div className={`flex justify-between text-sm ${index === 0 ? 'pt-[3px]' : ''}`}>
                  <div className="flex items-top">
                    <Checkbox
                      icon={<CheckCircleOutlineIcon color={colors.gray[4]} />}
                      checkedIcon={<CheckCircleSolidIcon color={colors.primary} />}
                      sx={{
                        color: colors.gray[4],
                        '&.Mui-checked': {
                          color: colors.primary,
                        },
                      }}
                      onChange={(e) => handleSingleCheck(e.target.checked, item.id)}
                      checked={checkList.includes(item.id)}
                    />
                    <div
                      className="text-gray-8 font-medium py-2 pr-2"
                      onClick={() => handleSingleCheck(!checkList.includes(item.id), item.id)}
                    >
                      {item.title}
                      <span className="text-gray-6 font-normal">{item.isRequired ? '(필수)' : '(선택)'}</span>
                    </div>
                  </div>

                  <span
                    className="text-gray-6 font-semibold text-xs leading-10 cursor-pointer w-[30px]"
                    onClick={() => onClickTermDetail(item.type)}
                  >
                    보기
                  </span>
                </div>
              </div>
            ))}
          </section>
        </div>
        <div className="pb-2">
          <BasicButton
            name="다음"
            bgColor={colors.primary}
            borderColor={colors.primary}
            textColor={colors.gray[0]}
            fontSize={16}
            height={48}
            borderRadius={10}
            fontWeight="semibold"
            onClick={() => goNext()}
            isDisabled={!isSuccess}
          ></BasicButton>
        </div>
      </div>
    </>
  );
};

export default PhoneAuthAndTerms;
