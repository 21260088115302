import React from 'react';

import BasicButton from './BasicButton';
import { colors } from '@/const/colors';

export interface ButtonProps {
  leftButtonText: string;
  onClickLeftButton: () => void;
  disabledLeft?: boolean;
  rightButtonText: string;
  onClickRightButton: () => void;
  disabledRight?: boolean;
  shadow?: boolean;
}

const DualFooterButton = ({
  leftButtonText,
  onClickLeftButton,
  disabledLeft = false,
  rightButtonText,
  onClickRightButton,
  disabledRight = false,
  shadow = false,
}: ButtonProps) => {
  return (
    <div
      className={`flex items-center justify-center fixed w-full mx-auto my-0 px-4 pb-2 bottom-0 left-0 right-0 h-14 max-w-[720px] min-w-[280px] bg-white ${
        shadow ? 'shadow-[0_-2px_4px_rgba(0,0,0,0.05)]' : ''
      } z-30`}
    >
      <div className="absolute top-[-16px] left-0 w-full h-[16px] bg-gradient-to-b from-transparent to-[#ffffff]"></div>
      <div className="w-1/3 min-w-[80px] mr-2">
        <BasicButton
          name={leftButtonText}
          bgColor={colors.gray[0]}
          borderColor={colors.gray[3]}
          textColor={colors.gray[6]}
          fontSize={16}
          height={48}
          fontWeight="bold"
          isDisabled={disabledLeft}
          onClick={onClickLeftButton}
        ></BasicButton>
      </div>
      <div className="w-full min-w-[180px]">
        <BasicButton
          name={rightButtonText}
          bgColor={colors.primary}
          borderColor={colors.primary}
          textColor={colors.gray[0]}
          fontSize={16}
          height={48}
          fontWeight="bold"
          isDisabled={disabledRight}
          onClick={onClickRightButton}
        ></BasicButton>
      </div>
    </div>
  );
};

export default DualFooterButton;
