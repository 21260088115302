import { useMemo } from 'react';
import { QueryFunctionContext, useInfiniteQuery } from 'react-query';

interface useSearchLicenseQueryProps {
  rowsPerPage: number;
  queryFn: (context: QueryFunctionContext<any>) => Promise<any>;
  searchParams: LicenseSearch;
}

const useSearchLicenseQuery = ({ rowsPerPage, queryFn, searchParams }: useSearchLicenseQueryProps) => {
  const queryKey = ['get-license-list', searchParams];
  const { data, isLoading, isFetching, isError, hasNextPage, fetchNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery<any>(queryKey, queryFn, {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage?.data.length === 0 || lastPage?.data.length < rowsPerPage ? undefined : nextPage;
      },
      refetchOnWindowFocus: false,
      // cacheTime: Infinity,
      // staleTime: Infinity,
      // keepPreviousData: true,
    });

  const licenseList = useMemo(() => {
    return data?.pages.flatMap((page) => page?.data);
  }, [data]);

  return {
    licenseList,
    isLoading,
    isFetching,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  };
};

export default useSearchLicenseQuery;
