import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import NoAxisWarning from '../../NoAxisWarning';
import RadioList, { OptionType } from '@/components/Common/Input/Radio/RadioList';
import TextInputLabelOff from '@/components/Common/Input/TextInputLabelOff';
import TextInputLabelUp from '@/components/Common/Input/TextInputLabelUp';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import { ChevronDownIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import { MIN_LENGTH_ERROR_MESSAGE } from '@/const/errorMessage';
import { positiveFloatRegex } from '@/const/regex';
import { useProductEnumContext } from '@/contexts/Products/ProductEnumContext';
import { isLengthOnlyLoadedType, shouldShowOnlyLength } from '@/utils/Products/productUtils';
import { formatNumber, formatValueToDecimalPlaces, isUnderFourTons, updateSearchParams } from '@/utils/common';
import {
  getTonnageErrorMessage,
  processDistanceInput,
  processPowerInput,
  processTonnageInput,
  validateDistance,
  validateLoadedInnerLength,
  validatePower,
} from '@/utils/validation';

interface ProductProps {
  productInfo: ProductDetailResponse;
  setProductInfo: React.Dispatch<React.SetStateAction<ProductDetailResponse>>;
  updateDisabledBtn: (value: boolean) => void;
  isEtc: boolean;
}

const CarInfo: React.FC<ProductProps> = ({ productInfo, setProductInfo, updateDisabledBtn, isEtc }) => {
  const keyValueList: KeyValueListType = {
    manufacturerCategories: '제조사',
    model: '모델',
    color: '색상',
    loaded: '적재함 종류',
    transmission: '변속기',
    fuel: '연료',
    garage: '차고지',
  };
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const { productEnum, setProductEnum } = useProductEnumContext();
  const [isShow, setIsShow] = useState(false);
  const [title, setTitle] = useState('');
  const [disabledModel, setDisabledModel] = useState<boolean>(false);
  const [tonsError, setTonsError] = useState<boolean>(false);
  const [tonsErrorMsg, setTonsErrorMsg] = useState('');
  const [loadedInnerLengthError, setLoadedInnerLengthError] = useState<boolean>(false);
  const [loadedInnerLengthErrorMsg, setLoadedInnerLengthErrorMsg] = useState('');
  const [loadedInnerAreaError, setLoadedInnerAreaError] = useState<boolean>(false);
  const [loadedInnerAreaErrorMsg, setLoadedInnerAreaErrorMsg] = useState('');
  const [loadedInnerHeightError, setLoadedInnerHeightError] = useState<boolean>(false);
  const [loadedInnerHeightErrorMsg, setLoadedInnerHeightErrorMsg] = useState('');
  const [distanceError, setDistanceError] = useState<boolean>(false);
  const [distanceErrorMsg, setDistanceErrorMsg] = useState('');
  const [powerError, setPowerError] = useState(false);
  const [powerErrorMsg, setPowerErrorMsg] = useState('');

  const loadedLengthInputRef = useRef<HTMLInputElement>(null);
  const loadedAreaInputRef = useRef<HTMLInputElement>(null);
  const loadedHeightInputRef = useRef<HTMLInputElement>(null);
  const distanceRef = useRef<HTMLInputElement>(null);
  const fuelRef = useRef<HTMLInputElement>(null);
  const powerRef = useRef<HTMLInputElement>(null);
  const colorRef = useRef<HTMLInputElement>(null);
  const garageRef = useRef<HTMLInputElement>(null);

  const getFilterManufacturerCategories = () => {
    if (productInfo?.manufacturerCategories && productEnum) {
      const manufacturerCategories = productEnum['manufacturerAndModel'].map((item) => item.manufacturerCategories);
      return manufacturerCategories;
    }
    return [];
  };

  const getFilteredModels = () => {
    if (productInfo?.manufacturerCategories && productEnum) {
      const models = productEnum['manufacturerAndModel']
        .filter((item) => item.manufacturerCategories.id === productInfo.manufacturerCategories?.id)
        .map((item) => item.model);
      return models;
    }
    return [];
  };

  const onClickSelectBox = (key: string) => {
    if (!productEnum) return;

    setTitle(key);

    if (key === 'model') {
      if (disabledModel || !productInfo?.manufacturerCategories) return;
      const filteredModels = getFilteredModels();
      if (filteredModels.length > 0) {
        const flattenedArr = filteredModels.reduce<Model[]>((acc, val) => acc.concat(val), []);
        const option = flattenedArr.map((item, index) => {
          return { code: String(item.id), desc: item.name };
        });
        setOptionData(option);
      }
    } else if (key === 'manufacturerCategories') {
      if (!isEtc) return;
      const filteredManufacturerCategories = getFilterManufacturerCategories();

      if (filteredManufacturerCategories.length > 0) {
        const flattenedArr = filteredManufacturerCategories.reduce<Model[]>((acc, val) => acc.concat(val), []);
        const option = flattenedArr.map((item, index) => {
          return { code: String(item.id), desc: item.name };
        });
        setOptionData(option);
      }
    } else {
      setOptionData(productEnum[key]);
    }
    setIsShow(true);
  };

  const onClickItem = (item: OptionDataType, key: string) => {
    let value = { id: Number(item.code), name: item.desc };
    if (key === 'manufacturerCategories') {
      setProductInfo({
        ...productInfo,
        manufacturerCategories: value,
        model: {},
      } as ProductDetailResponse);
    } else if (key === 'model') {
      const value = { id: Number(item.code), name: item.desc };
      setProductInfo({ ...productInfo, [key]: value } as ProductDetailResponse);
    } else {
      setProductInfo({ ...productInfo, [key]: item } as ProductDetailResponse);
    }

    setTimeout(() => {
      setIsShow(false);
    }, 200);
  };

  const handleChangeLoadedInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;

    const decimalPlaces = 1;
    const formattedValue = formatValueToDecimalPlaces(value, decimalPlaces);

    if (name === 'loadedInnerLength' && productInfo) {
      const { hasError, errorMsg } = validateLoadedInnerLength(
        Number(formattedValue),
        Number(productInfo.tons),
        productInfo.manufacturerCategories.code,
        productInfo.model.id,
      );
      setLoadedInnerLengthError(hasError);
      setLoadedInnerLengthErrorMsg(errorMsg);
    } else if (name === 'loadedInnerArea' || name === 'loadedInnerHeight') {
      const isBelowMinimum = formattedValue && Number(formattedValue) < 1;
      setLoadedInnerAreaError(name === 'loadedInnerArea' && (!formattedValue || !!isBelowMinimum));
      setLoadedInnerAreaErrorMsg(name === 'loadedInnerArea' && isBelowMinimum ? MIN_LENGTH_ERROR_MESSAGE : '');
      setLoadedInnerHeightError(name === 'loadedInnerHeight' && (!formattedValue || !!isBelowMinimum));
      setLoadedInnerHeightErrorMsg(name === 'loadedInnerHeight' && isBelowMinimum ? MIN_LENGTH_ERROR_MESSAGE : '');
    }

    if (positiveFloatRegex.test(formattedValue.trim()) || formattedValue.trim() === '') {
      const str = formattedValue.trim() === '' ? null : formattedValue.trim();
      setProductInfo({ ...productInfo, [name]: str } as ProductDetailResponse);
    }
  };

  useEffect(() => {
    const filteredModels = getFilteredModels();
    if (filteredModels.length === 1) {
      setDisabledModel(true);

      const flattenedArr = filteredModels.reduce<Model[]>((acc, val) => acc.concat(val), []);
      const [firstModel] = flattenedArr;

      if (flattenedArr.length === 1) {
        setProductInfo({
          ...productInfo,
          model: { id: firstModel.id, name: firstModel.name },
        } as ProductDetailResponse);
      } else {
        setDisabledModel(false);
      }
    }
  }, [productInfo.manufacturerCategories]);

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
    let { value } = e.target;
    const valueNum = Number(value);
    if (valueNum >= 100) {
      const { updatedParams, error, errorMsg } = updateSearchParams(productInfo, key, valueNum);
      setProductInfo(updatedParams);
      switch (key) {
        case 'loadedInnerLength':
          setLoadedInnerLengthError(error);
          setLoadedInnerLengthErrorMsg(errorMsg);
          break;
        case 'loadedInnerArea':
          setLoadedInnerAreaError(error);
          setLoadedInnerAreaErrorMsg(errorMsg);
          break;
        case 'loadedInnerHeight':
          setLoadedInnerHeightError(error);
          setLoadedInnerHeightErrorMsg(errorMsg);
          break;
      }
    }
  };

  useEffect(() => {
    if (productInfo?.loadedInnerLength) {
      const { hasError, errorMsg } = validateLoadedInnerLength(
        productInfo.loadedInnerLength,
        Number(productInfo.tons),
        productInfo.manufacturerCategories.code,
        productInfo.model.id,
      );
      setLoadedInnerLengthError(hasError);
      setLoadedInnerLengthErrorMsg(errorMsg);
    }
  }, [productInfo?.loadedInnerLength, productInfo?.tons]);

  const scrollIntoView = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e) {
      const { name } = e.target;
      if (name === 'loadedInnerLength') {
        loadedLengthInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerArea') {
        loadedAreaInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      } else if (name === 'loadedInnerHeight') {
        loadedHeightInputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      }
    }
  };

  useLayoutEffect(() => {
    const detectMobileKeyboard = () => {
      scrollIntoView();
    };
    window.addEventListener('resize', detectMobileKeyboard);
    return () => {
      window.removeEventListener('resize', detectMobileKeyboard);
    };
  });

  const onChangeRadioInput = (newVal: OptionType, key: string) => {
    setProductInfo({ ...productInfo, [key]: newVal } as ProductDetailResponse);
  };

  const isValidatedLoaded = () => {
    if (!productInfo?.loaded) {
      return true;
    } else if (isLengthOnlyLoadedType(productInfo)) {
      return !productInfo?.loadedInnerLength || loadedInnerLengthError;
    } else {
      return (
        !productInfo?.loadedInnerLength ||
        !productInfo?.loadedInnerArea ||
        !productInfo?.loadedInnerHeight ||
        loadedInnerLengthError ||
        loadedInnerAreaError ||
        loadedInnerHeightError
      );
    }
  };

  useEffect(() => {
    updateDisabledBtn(
      !productInfo?.manufacturerCategories?.name ||
        !productInfo?.model?.name ||
        tonsError ||
        !!isValidatedLoaded() ||
        distanceError ||
        powerError,
    );
  }, [
    productInfo?.manufacturerCategories?.name,
    productInfo?.model?.name,
    tonsError,
    loadedInnerLengthError,
    loadedInnerAreaError,
    loadedInnerHeightError,
    distanceError,
    powerError,
  ]);

  const handleChangeTonsInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processTonnageInput(value, 1);

    setProductInfo({ ...productInfo, [name]: trimmedValue } as ProductDetailResponse);

    const errorMessage = getTonnageErrorMessage(trimmedValue);

    if (errorMessage) {
      setTonsError(true);
      setTonsErrorMsg(errorMessage);
    } else {
      setTonsError(false);
      setTonsErrorMsg('');
    }
  };

  const handleChangeDistanceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    const trimmedValue = processDistanceInput(value);
    const { isValid, errorMessage } = validateDistance(trimmedValue);

    setProductInfo({ ...productInfo, [name]: trimmedValue } as ProductDetailResponse);

    if (!isValid) {
      setDistanceError(true);
      setDistanceErrorMsg(errorMessage);
    } else {
      setDistanceError(false);
      setDistanceErrorMsg('');
    }
  };

  const handleChangePowerInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;

    const trimmedValue = processPowerInput(value);

    const { isValid, errorMessage } = validatePower(trimmedValue);

    setProductInfo({ ...productInfo, [name]: trimmedValue } as ProductDetailResponse);

    if (!isValid) {
      setPowerError(true);
      setPowerErrorMsg(errorMessage);
    } else {
      setPowerError(false);
      setPowerErrorMsg('');
    }
  };

  const isShowOnlyLength = shouldShowOnlyLength(productInfo);

  const handleBlurTonsInput = () => {
    if (isUnderFourTons(productInfo.tons)) {
      setProductInfo({
        ...productInfo,
        axis: { code: 'NONE', desc: '없음' },
      });
    }
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-[30px]">
        {isEtc && (
          <div className="py-5 px-4 rounded-[10px] bg-gray-1 text-red font-medium text-sm xxs:text-xs leading-[18px]">
            * 기타(쌍용 외) 제조사에서 타 제조사로 변경 시 추후 수정이 불가능하니 유의해주세요.
          </div>
        )}

        <div onClick={() => onClickSelectBox('manufacturerCategories')}>
          <TextInputLabelUp
            name="manufacturerCategories"
            label="제조사"
            placeholder="제조사 선택"
            value={productInfo?.manufacturerCategories?.name}
            type="text"
            disabled={!isEtc || false}
            readOnly
            suffix={isEtc && <ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
            required
          ></TextInputLabelUp>
        </div>

        <div onClick={() => onClickSelectBox('model')}>
          <TextInputLabelUp
            name="model"
            label="모델"
            placeholder="모델 선택"
            value={productInfo?.model?.name || ''}
            type="text"
            readOnly
            required
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>

        <TextInputLabelUp
          name="tons"
          label="톤수"
          placeholder="톤수 입력"
          value={productInfo?.tons || ''}
          onChange={(e) => handleChangeTonsInput(e)}
          onBlur={() => handleBlurTonsInput()}
          error={tonsError}
          errorMsg={tonsErrorMsg}
          suffix="t"
          required
          maxLength={4}
        ></TextInputLabelUp>

        <div onClick={() => onClickSelectBox('loaded')}>
          <TextInputLabelUp
            name="loaded"
            label="적재함 종류"
            placeholder="적재함 종류 선택"
            value={productInfo?.loaded?.desc || ''}
            type="text"
            readOnly
            required
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>

        <div>
          <label className="text-base font-medium mb-3 text-gray-8">
            적재함 길이(내측 사이즈)
            <span className="font-normal text-red">(필수)</span>
          </label>
          <div className="w-full flex justify-center items-center pt-2">
            <label className="text-base font-medium text-gray-8 min-w-[60px]">길이</label>
            <TextInputLabelOff
              ref={loadedLengthInputRef}
              name="loadedInnerLength"
              placeholder="길이 입력"
              value={productInfo?.loadedInnerLength ? String(productInfo?.loadedInnerLength) : ''}
              onChange={(e) => handleChangeLoadedInput(e)}
              onBlur={(e) => handleInputBlur(e, 'loadedInnerLength')}
              onFocus={(e) => scrollIntoView(e)}
              error={loadedInnerLengthError}
              errorMsg={loadedInnerLengthErrorMsg}
              fontSize={18}
              height={36}
              inputMode="numeric"
              suffix="m"
              maxLength={4}
            ></TextInputLabelOff>
          </div>
          {!isShowOnlyLength && (
            <>
              <div className="w-full flex justify-center items-center pt-5">
                <label className="text-base font-medium text-gray-8 min-w-[60px]">너비</label>
                <TextInputLabelOff
                  ref={loadedAreaInputRef}
                  name="loadedInnerArea"
                  placeholder="너비 입력"
                  value={productInfo?.loadedInnerArea ? String(productInfo?.loadedInnerArea) : ''}
                  onChange={(e) => handleChangeLoadedInput(e)}
                  onBlur={(e) => handleInputBlur(e, 'loadedInnerArea')}
                  onFocus={(e) => scrollIntoView(e)}
                  error={loadedInnerAreaError}
                  errorMsg={loadedInnerAreaErrorMsg}
                  fontSize={18}
                  height={36}
                  inputMode="numeric"
                  suffix="m"
                  maxLength={4}
                ></TextInputLabelOff>
              </div>

              <div className="w-full flex justify-center items-center pt-5">
                <label className="text-base font-medium text-gray-8 min-w-[60px]">높이</label>
                <TextInputLabelOff
                  ref={loadedHeightInputRef}
                  name="loadedInnerHeight"
                  placeholder="높이 입력"
                  value={productInfo?.loadedInnerHeight ? String(productInfo?.loadedInnerHeight) : ''}
                  onChange={(e) => handleChangeLoadedInput(e)}
                  onBlur={(e) => handleInputBlur(e, 'loadedInnerHeight')}
                  onFocus={(e) => scrollIntoView(e)}
                  error={loadedInnerHeightError}
                  errorMsg={loadedInnerHeightErrorMsg}
                  fontSize={18}
                  height={36}
                  inputMode="numeric"
                  suffix="m"
                  maxLength={4}
                ></TextInputLabelOff>
              </div>
            </>
          )}
        </div>

        <div>
          <label className="text-base font-medium text-gray-8">
            가변축
            <span className="font-normal text-red">(필수)</span>
          </label>

          <NoAxisWarning tons={productInfo?.tons} className="my-2" />

          <div className="px-1">
            <RadioList
              name="axis"
              list={productEnum?.axis || []}
              value={productInfo?.axis?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'axis');
              }}
              isDisabled={isUnderFourTons(productInfo?.tons)}
            ></RadioList>
          </div>
        </div>

        <div>
          <label className="text-base font-medium text-gray-8">
            변속기
            <span className="font-normal text-red">(필수)</span>
          </label>
          <div className="px-1">
            <RadioList
              name="transmission"
              list={(productEnum?.transmission.filter((item) => item.code !== 'BOTH') as OptionType[]) || []}
              value={productInfo?.transmission?.code || ''}
              onChange={(val) => {
                onChangeRadioInput(val, 'transmission');
              }}
              justify="equal"
            ></RadioList>
          </div>
        </div>

        <TextInputLabelUp
          ref={distanceRef}
          name="distance"
          label="주행거리"
          placeholder="주행거리 입력"
          value={formatNumber(productInfo?.distance?.toString(), true) || ''}
          onChange={(e) => handleChangeDistanceInput(e)}
          onFocus={(e) => scrollIntoView(e)}
          inputMode="numeric"
          required
          error={distanceError}
          errorMsg={distanceErrorMsg}
          suffix="km"
          maxLength={8}
        ></TextInputLabelUp>

        <div onClick={() => onClickSelectBox('fuel')}>
          <TextInputLabelUp
            ref={fuelRef}
            name="fuel"
            label="연료"
            placeholder="연료 선택"
            value={productInfo?.fuel?.desc || ''}
            type="text"
            readOnly
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
            required
          ></TextInputLabelUp>
        </div>

        <TextInputLabelUp
          ref={powerRef}
          name="power"
          label="마력수"
          placeholder="마력수 입력"
          value={productInfo?.power || ''}
          error={powerError}
          errorMsg={powerErrorMsg}
          onChange={(e) => handleChangePowerInput(e)}
          onFocus={(e) => scrollIntoView(e)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              onClickSelectBox('fuel');
            }
          }}
          required
          inputMode="numeric"
          maxLength={3}
        ></TextInputLabelUp>

        <div onClick={() => onClickSelectBox('color')}>
          <TextInputLabelUp
            ref={colorRef}
            name="color"
            label="색상"
            placeholder="색상 선택"
            value={productInfo?.color?.desc || ''}
            type="text"
            readOnly
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>

        <div onClick={() => onClickSelectBox('garage')}>
          <TextInputLabelUp
            ref={garageRef}
            name="garage"
            label="차고지"
            placeholder="차고지 선택"
            value={productInfo?.garage?.desc || ''}
            type="text"
            readOnly
            suffix={<ChevronDownIcon color={colors.gray[8]}></ChevronDownIcon>}
          ></TextInputLabelUp>
        </div>
      </div>

      <DownToUpDetailPopup isShow={isShow} onClosePopup={() => setIsShow(false)} title={keyValueList[title]}>
        <div className="px-4 pb-8">
          <RadioList
            name={title}
            list={optionData}
            value={
              (productInfo && productInfo[title]?.id?.toString()) || (productInfo && productInfo[title]?.code) || ''
            }
            onChange={(val) => {
              onClickItem(val, title);
            }}
            horizontal={false}
          ></RadioList>
        </div>
      </DownToUpDetailPopup>
    </div>
  );
};

export default CarInfo;
