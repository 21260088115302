import React, { Suspense, lazy, useEffect } from 'react';

import { ChevronLeftIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import '@/css/imageModalStyle.css';

const TransformComponent = lazy(() =>
  import('react-zoom-pan-pinch').then((module) => ({ default: module.TransformComponent })),
);
const TransformWrapper = lazy(() =>
  import('react-zoom-pan-pinch').then((module) => ({ default: module.TransformWrapper })),
);

interface ImageModalProps {
  isShow: boolean;
  onClose: () => void;
  img: string;
}
const ImageModal = ({ isShow, onClose, img }: ImageModalProps) => {
  useEffect(() => {
    if (isShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShow]);

  return (
    <>
      {isShow && (
        <div className="fixed flex flex-col items-center justify-center z-50 w-full h-screen bg-black top-0 max-w-[720px] pb-[24px] overflow-hidden">
          <div className="absolute top-0 left-0 m-4 z-[1000]" onClick={onClose}>
            <ChevronLeftIcon color={colors.gray[0]} />
          </div>
          <div className="custom-transform-wrapper">
            <Suspense fallback={<></>}>
              <TransformWrapper
                initialScale={1}
                minScale={1}
                maxScale={5}
                limitToBounds={true}
                wheel={{ disabled: false }}
                pinch={{ disabled: false }}
                panning={{ disabled: false }}
              >
                <TransformComponent>
                  <div>
                    <img src={img} alt="이미지" className="w-full object-center"></img>
                  </div>
                </TransformComponent>
              </TransformWrapper>
            </Suspense>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageModal;
