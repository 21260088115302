import { useAtom } from 'jotai';
import jwtDecode from 'jwt-decode';

import { useMemberContext } from '@/contexts/Member/MemberContext';
import { getMemberAtom } from '@/store/member';

const useSetMemberData = (token: string) => {
  const { getMember } = useMemberContext();
  const [, getMemberInfoAtom] = useAtom(getMemberAtom);

  const settingMember = (token: string) => {
    localStorage.setItem('token', token);
    if (token) {
      const decodedToken: Token = jwtDecode(token);
      const parse = JSON.parse(decodedToken.details.toString());
      if (parse && getMember) {
        getMember(parse['memberId']);
      }
    }
  };

  return settingMember;
};

export default useSetMemberData;
