import React from 'react';
import { UseMutationOptions } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import BasicButton from '../Common/Button/BasicButton';
import { ZigtruckCircleColorIcon } from '../Icon';
import apiManager from '@/api/AxiosInstance';
import { TEXT } from '@/const/chat';
import { colors } from '@/const/colors';
import { ALREADY_REQUEST_TRANSFER_AGENCY_SERVICE } from '@/const/errorCode';
import { COMMON_TOAST_ERROR, REQUEST_TRANSFER_AGENCY_SERVICE_ERROR } from '@/const/errorMessage';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { FREQUENTLY_ASKED_MESSAGE, TEXT_API_BUTTON, TEXT_NAVIGATE_BUTTON, TEXT_URL_BUTTON } from '@/const/scenario';
import { useToastContext } from '@/contexts/Common/ToastContext';
import { getFormatMessageDateTime } from '@/utils/common';

type ScenarioMessageProps = {
  productId: number | undefined;
  chatRoomData: ChattingDetailResponse | undefined;
  memberId: number | undefined;
  contractWriterType: string;
  chatMessage: ChatMessage;
  addChatData: (chatData: ChatMessage) => void;
  handleSendMessage: (
    data: ChatMessageRequest,
    options?: UseMutationOptions<ChatMessageData, unknown, ChatMessageRequest>,
  ) => void;
  updateChatRoomId: (id: number) => void;
  isBuyer: boolean;
};

type ChatButtonType = {
  text: string;
  url: string;
  method: string | null;
  desc?: string;
};

const ScenarioMessage = ({
  productId,
  chatRoomData,
  memberId,
  contractWriterType,
  chatMessage,
  addChatData,
  updateChatRoomId,
  handleSendMessage,
  isBuyer,
}: ScenarioMessageProps) => {
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const { chatRoomId } = useParams();

  const renderTextContent = (textContent: string) => {
    const paragraphs = textContent.split('\n');
    const underlineRegex = /안심 전자계약서 작성하기/g;

    return (
      <>
        {paragraphs.map((paragraph, index) => {
          if (underlineRegex.test(paragraph)) {
            const modifiedParagraph = paragraph.replace(
              underlineRegex,
              '<span class="underline underline-offset-2">안심 전자계약서 작성하기</span>',
            );
            return <div className="mt-[10px]" key={index} dangerouslySetInnerHTML={{ __html: modifiedParagraph }} />;
          } else {
            return <div key={index}>{paragraph}</div>;
          }
        })}
      </>
    );
  };

  const getScenarioButtonType = () => {
    return JSON.parse(chatMessage.contents).type;
  };

  const getScenarioName = () => {
    return JSON.parse(chatMessage.contents).name;
  };

  const getScenarioButtons = () => {
    return JSON.parse(chatMessage.contents).buttons;
  };

  const isTipMessage = getScenarioName() != FREQUENTLY_ASKED_MESSAGE;

  const getHeader = () => {
    if (!isTipMessage) {
      return {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      };
    }
  };

  const onSuccess = (response: any) => {
    if (isTipMessage) {
      showToast('신청이 완료되었어요.', 'success', 'bottom');
    } else {
      const res: ChatMessageData = response.data;
      const message = { ...(res as ChatMessage), senderId: memberId || 0, isLoading: false };
      addChatData(message as ChatMessage);
      if (chatRoomId) {
        return;
      } else if (res.chatRoom && res.chatRoom.id) {
        updateChatRoomId(res.chatRoom.id);
        navigate('/chatting/room', { state: { chatRoomId: res.chatRoom.id } });
      }
    }
  };

  const onError = (error: any) => {
    if (isTipMessage) {
      if (error.code === ALREADY_REQUEST_TRANSFER_AGENCY_SERVICE) {
        showToast(error.message, 'error', 'bottom');
      } else {
        showToast(REQUEST_TRANSFER_AGENCY_SERVICE_ERROR, 'error', 'bottom');
      }
    } else {
      showToast(COMMON_TOAST_ERROR, 'error', 'bottom');
    }
  };

  const onClickScenarioButton = async (button: ChatButtonType) => {
    switch (getScenarioButtonType()) {
      case TEXT_URL_BUTTON:
        return window.open(button.url);
      case TEXT_NAVIGATE_BUTTON:
        return navigate(button.url, { state: { chatRoomId: chatRoomData?.id, contractWriterType } });
      case TEXT_API_BUTTON:
        if (button.method === 'POST') {
          if (button.url === '/api/v1/chat-messages') {
            const tempId = Date.now();

            const formData = new FormData();
            if (chatRoomId || (chatRoomData && chatRoomData.id)) {
              formData.append('chatRoomId', String(chatRoomId || (chatRoomData && chatRoomData.id)));
            }
            formData.append('productId', String(productId));
            formData.append('contents', button.text);
            formData.append('type', TEXT);

            const request = {
              formData: formData,
              type: TEXT,
              contents: button.text,
              tempId: tempId,
              senderId: memberId || 0,
              isLoading: true,
            };

            handleSendMessage(request, {
              onSuccess: (data) => {
                if (chatRoomId) {
                  return;
                } else if (data.chatRoom && data.chatRoom.id) {
                  updateChatRoomId(data.chatRoom.id);
                  navigate('/chatting/room', { state: { chatRoomId: data.chatRoom.id }, replace: true });
                }
              },
            });
          } else {
            apiManager
              .post(button.url, { chatRoomId: chatRoomData?.id }, getHeader())
              .then((response) => {
                onSuccess(response);
              })
              .catch((error) => {
                onError(error);
              });
          }
        }
        return;
    }
  };

  const isOnlyOneButton = () => {
    return getScenarioButtons().length === 1;
  };

  return (
    <>
      {isTipMessage ? (
        <>
          <div className="w-full bg-blue-0 p-[18px] rounded-lg my-[14px] text-primary">
            <div className="text-[13px] text-primary leading-[16px] font-medium">
              {renderTextContent(JSON.parse(chatMessage.contents).text)}
            </div>
            <div className={`flex flex-col gap-[20px] mt-[20px] ${isOnlyOneButton() ? 'mt-[6px]' : 'mt-[20px]'}`}>
              {getScenarioButtons()?.map((button: ChatButtonType, buttonIndex: number) => (
                <div key={buttonIndex} className="flex items-center gap-2">
                  {isOnlyOneButton() ? (
                    <div
                      className="font-semibold text-[14px] underline underline-offset-4"
                      onClick={() => onClickScenarioButton(button)}
                    >
                      {button.text}
                    </div>
                  ) : (
                    <div className="flex flex-col item-center justify-center w-full">
                      {button.desc && (
                        <div className="font-semibold mb-2 break-keep">
                          <span dangerouslySetInnerHTML={{ __html: button.desc }}></span>
                        </div>
                      )}
                      <BasicButton
                        name={button.text}
                        bgColor={colors.blue[8]}
                        borderColor={colors.blue[8]}
                        textColor={colors.gray[0]}
                        fontSize={14}
                        height={48}
                        fontWeight="bold"
                        onClick={() => onClickScenarioButton(button)}
                      ></BasicButton>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          {isBuyer && (
            <>
              <div className="flex">
                <div className="profile">
                  <ZigtruckCircleColorIcon width={40} height={40}></ZigtruckCircleColorIcon>
                </div>
                <div className="ml-2 bg-blue-0 p-3 rounded-r-3xl rounded-b-3xl break-all border border-blue-2 min-w-[280px] xs:min-w-0 xxs:min-w-0">
                  <span className="text-gray-8 font-medium text-[14px] xs:text-[13px] xxs:text-[13px]">
                    {renderTextContent(JSON.parse(chatMessage.contents).text)}
                  </span>
                </div>
              </div>

              <div className="pl-12">
                <div className="flex flex-col gap-[6px] mt-3">
                  {getScenarioButtons()?.map((button: ChatButtonType, buttonIndex: number) =>
                    button.text ? (
                      <div
                        key={buttonIndex}
                        className="w-min whitespace-nowrap inline-block text-[12px] h-[38px] w-auto rounded-[30px] font-medium px-[14px] py-3 bg-gray-1 border border-gray-3 text-gray-8 leading-[14px] hover:bg-blue-8 hover:text-gray-0 hover:border-blue-8"
                        onClick={() => onClickScenarioButton(button)}
                      >
                        {button.text}
                      </div>
                    ) : null,
                  )}
                </div>

                <p className="text-gray-400 text-xs text-left mt-1">
                  {getFormatMessageDateTime(chatMessage.createdDate)}
                </p>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ScenarioMessage;
