import React from 'react';

import LoanCalculator from '@/components/Common/LoanCalculator';

interface CapitalInfoProps {
  price?: number;
}

const CapitalInfo = ({ price }: CapitalInfoProps) => {
  return (
    <div className="p-4 my-2 text-gray-8">
      <div className="w-full">
        <h1 className="font-semibold text-lg mb-4">할부 계산기</h1>
        <LoanCalculator price={price || 0} />
      </div>
    </div>
  );
};

export default CapitalInfo;
