import React, { useState } from 'react';

import LoadingComponent from '../LoadingComponent';
import apiManager from '@/api/AxiosInstance';
import {
  CameraSolidIcon,
  DocumentArrowUpSolidIcon,
  TruckBackSideSolidIcon,
  TruckFrontSideSolidIcon,
  TruckFrontSolidIcon,
} from '@/components/Icon';
import { colors } from '@/const/colors';
import { MULTIPART_FORM_DATA } from '@/const/headers';
import { useToastContext } from '@/contexts/Common/ToastContext';

interface productImageType {
  id: string;
  name: string;
  url: string;
  imageType: string;
}
interface FileUploaderProps {
  truckNumber: string;
  fileData: productImageType;
  setFileData: React.Dispatch<React.SetStateAction<productImageType>>;
  fileNameMaxLength?: number;
  imageType: string;
  isLoading?: boolean;
  icon?: string;
  accept?: string;
}

const FileUploader = ({
  truckNumber,
  imageType,
  fileData,
  setFileData,
  isLoading,
  icon,
  accept = '*',
}: FileUploaderProps) => {
  const { showToast } = useToastContext();
  const [src, setSrc] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const openFileDialog = () => {
    if (loading) return;
    const inputElement = document.getElementById(`upload-button-${fileData.id}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.click();
    }
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) return;

    let newFile = e.target.files[0];
    uploadImage(newFile);
    // TODO: licenese에서도 사용시 추가 분기 필요
  };

  const uploadImage = async (file: File) => {
    const reader = new FileReader();
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('uploadFile', file);
    formData.append('truckNumber', truckNumber);
    formData.append('imageType', imageType);

    try {
      setLoading(true);
      reader.onload = () => {
        setSrc(reader.result as string);
        setFileName(file.name);
      };

      reader.readAsDataURL(file);
      const response = await apiManager.post('/api/v1/products-images', formData, {
        headers: {
          'Content-Type': MULTIPART_FORM_DATA,
        },
      });
      if (response.data) {
        setFileData((prevData) => ({
          ...prevData,
          url: response.data?.url.toString(),
        }));
      }
    } catch (error) {
      showToast('이미지 업로드에 문제가 생겼어요.', 'error', 'bottom');
    } finally {
      setLoading(false);
    }
  };

  const onClickDelete = (e: React.MouseEvent) => {
    setSrc(undefined);
    setFileName('');
    e.stopPropagation();

    setFileData((prevData) => ({
      ...prevData,
      url: '',
    }));
  };

  return (
    <div
      className="relative flex flex-col items-center justify-center h-20 rounded-lg border text-sm min-w-[80px] overflow-hidden whitespace-no-wrap overflow-ellipsis bg-gray-1 border-gray-3 text-gray-6 cursor-pointer"
      onClick={openFileDialog}
    >
      {isLoading || loading ? (
        <LoadingComponent />
      ) : fileData.url !== '' ? (
        <>
          {imageType === 'CERTIFICATE_IMAGE' ? (
            <span>{fileData.name}</span>
          ) : (
            <img src={fileData.url} alt="truck_img" className={`rounded-lg w-full h-full object-cover h-20`} />
          )}
          <img
            src="https://zigtruck-service-public-image.s3.ap-northeast-2.amazonaws.com/clear.png"
            alt="clear"
            className="absolute top-1 right-1"
            onClick={(e) => onClickDelete(e)}
          />
        </>
      ) : (
        <>
          {(imageType && imageType === 'FRONT_SIDE_IMAGE') ||
          imageType === 'BACK_SIDE_IMAGE' ||
          imageType === 'FRONT_IMAGE' ? (
            <>
              {imageType === 'FRONT_SIDE_IMAGE' && (
                <TruckFrontSideSolidIcon color={colors.gray[5]} width={38} height={38} />
              )}
              {imageType === 'BACK_SIDE_IMAGE' && (
                <TruckBackSideSolidIcon color={colors.gray[5]} width={38} height={38} />
              )}
              {imageType === 'FRONT_IMAGE' && <TruckFrontSolidIcon color={colors.gray[5]} width={38} height={38} />}
            </>
          ) : (
            <>
              {icon === 'file' && <DocumentArrowUpSolidIcon color={colors.gray[5]} width={32} height={32} />}
              {icon === 'photo' && <CameraSolidIcon color={colors.gray[5]} width={38} height={38} />}
            </>
          )}
        </>
      )}

      <input
        id={`upload-button-${fileData?.id}`}
        className="hidden"
        type="file"
        accept={accept}
        onChange={(e) => handleChange(e)}
      ></input>
    </div>
  );
};

export default FileUploader;
