import React, { useState } from 'react';

import FilledBadge from '../Badge/FilledBadge';
import BasicButton from '../Button/BasicButton';
import DownToUpDetailPopup from '@/components/Common/Popup/DownToUpDetailPopup';
import { ChevronDownIcon, ChevronUpIcon } from '@/components/Icon';
import { colors } from '@/const/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Stack } from '@mui/material';

export interface ContentBoxProps {
  id: number;
  title: string;
  badgeLabel?: string;
  badgeTextColor?: string;
  detailContents: ContentBoxDetail[];
  expandContents?: ContentBoxDetail[];
  button?: string;
  buttonList?: ButtonProps[];
  buttonDisabled?: boolean;
  buttonOnClick?: (id: number) => void;
  isMore?: boolean;
  useExpand?: boolean;
  moreMenuData?: MenuType[];
}

export interface ContentBoxDetail {
  key: string;
  value: string;
}

const ContentBox = ({
  id,
  title,
  badgeLabel,
  badgeTextColor,
  detailContents,
  button,
  buttonList,
  buttonDisabled,
  buttonOnClick,
  useExpand = false,
  isMore = false,
  moreMenuData,
  expandContents,
}: ContentBoxProps) => {
  const [isShowMoreMenu, setIsShowMoreMenu] = useState<boolean>(false);
  const [isExpandDetail, setIsExpandDetail] = useState<boolean>(false);

  const onToggleMenu = () => {
    setIsShowMoreMenu(!isShowMoreMenu);
  };

  const onToggleDetail = () => {
    setIsExpandDetail(!isExpandDetail);
  };

  return (
    <>
      <ul className="grid grid-cols-1 gap-3 w-full">
        <li className="p-3 border rounded-lg px-4">
          {badgeLabel && badgeTextColor && (
            <div className="mt-1 mb-3">
              <FilledBadge
                text={badgeLabel}
                textColor={badgeTextColor}
                bgColor={'bg-gray-1'}
                rounded={'rounded-lg'}
                fontWeight={'font-semibold'}
              ></FilledBadge>
            </div>
          )}
          <div className="flex items-center pb-3">
            <div className="flex flex-1 justify-between items-center border-b-[1px]">
              <p className="text-lg xxs:text-base font-semibold text-gray-8 pb-3">{title}</p>
              {isMore && (
                <div className="cursor-pointer pb-3">
                  <MoreVertIcon onClick={onToggleMenu}></MoreVertIcon>
                </div>
              )}
              {useExpand && (
                <div className="cursor-pointer pb-3" onClick={onToggleDetail}>
                  {isExpandDetail ? (
                    <ChevronUpIcon color={colors.gray[7]} />
                  ) : (
                    <ChevronDownIcon color={colors.gray[7]} />
                  )}
                </div>
              )}
            </div>
          </div>
          <Stack spacing={2}>
            {detailContents.map((item, value) => (
              <div className="flex items-center" key={value}>
                <span className="flex-1 text-gray-7 text-base pr-4">{item.key}</span>
                <span className="text-base text-gray-8">{item.value}</span>
              </div>
            ))}
          </Stack>
          {isExpandDetail && expandContents && (
            <>
              <div className="bg-gray-1 rounded-lg p-4 my-4">
                {expandContents.map((item, index) => (
                  <div className={`flex items-center ${index === 0 ? 'mt-0' : 'mt-2'}`} key={index}>
                    <span className="flex-1 text-gray-7 text-base pr-4">{item.key}</span>
                    <span className="text-base text-gray-8">{item.value}</span>
                  </div>
                ))}
              </div>
              <div className="flex gap-2">
                {buttonList?.map((item, index) => (
                  <BasicButton key={index} {...item}></BasicButton>
                ))}
              </div>
            </>
          )}
          {button && buttonOnClick && (
            <div className="pt-3 mt-3">
              <BasicButton
                name={button}
                bgColor={colors.primary}
                borderColor={colors.primary}
                textColor={colors.gray[0]}
                fontSize={16}
                height={48}
                fontWeight="bold"
                isDisabled={buttonDisabled}
                onClick={() => buttonOnClick(id)}
              ></BasicButton>
            </div>
          )}
        </li>
      </ul>
      <DownToUpDetailPopup isShow={isShowMoreMenu} onClosePopup={onToggleMenu} title="메뉴">
        <div className="px-4 pb-8">
          {moreMenuData?.map((item, index) => (
            <div
              key={index}
              className={`mt-4 cursor-pointer  ${item.key === 'DELETE' && 'text-red'}`}
              onClick={() => {
                onToggleMenu();
                item.onClick();
              }}
            >
              {item.value}
            </div>
          ))}
        </div>
      </DownToUpDetailPopup>
    </>
  );
};

export default ContentBox;
