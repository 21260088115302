import dayjs, { Dayjs } from 'dayjs';

import {
  LOADED_INNER_LENGTH_IS_NOT_OVER_TEN_POINT_FIVE,
  LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE,
  LOADED_INNER_LENGTH_RANGE_ERROR_MESSAGE,
  MIN_LENGTH_ERROR_MESSAGE,
} from '@/const/errorMessage';

// 유틸 공통
export const oppenheimer = (emphaticWord: string) => `oppenheimer is ${emphaticWord}`;

export const formatNumber = (num: string | number | null | undefined, isInput?: boolean): string => {
  if (num === null || num === undefined || num === '') {
    return isInput ? '' : '0';
  }

  if (typeof num === 'number') {
    num = num.toString();
  }

  if (typeof num !== 'string') {
    return isInput ? '' : '0';
  }

  if (!/^\d*$/.test(num)) {
    return isInput ? '' : '0';
  }

  const formatted = num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formatted;
};

export const formatPrice = (price: string | null | undefined): string => {
  if (price === null || price === undefined) {
    return '0';
  }

  const numericPrice = Number(price);
  let result = price;
  if (numericPrice < 10000) {
    result = formatNumber(price);
  } else if (numericPrice >= 10000) {
    const billion = Math.floor(numericPrice / 10000); // 억
    const remainder = numericPrice % 10000; // 만원

    if (billion >= 1 && remainder === 0) {
      return `${billion}억원`;
    } else {
      return `${billion}억 ${formatNumber(remainder.toFixed(0))}만원`;
    }
  }

  return `${result}만원`;
};

export const formatShortYear = (year: string | number | undefined) => {
  if (year === undefined) {
    return '';
  }
  return String(year).slice(2);
};

export const getFormatDate = (
  dateTime: string | undefined,
  format: string[] = ['year', 'month', 'day'],
  shortYear: boolean = false,
) => {
  if (dateTime === undefined) {
    return '';
  }
  const [date, time] = dateTime.split(' ');
  const [year, month, day] = date.split('-');

  const fullYear = shortYear ? formatShortYear(year) : year;

  const resultArray = format.map((element) => {
    switch (element) {
      case 'year':
        return `${fullYear}년`;
      case 'month':
        return `${month}월`;
      case 'day':
        return `${day}일`;
      default:
        return ''; // Handle unknown format gracefully
    }
  });

  return resultArray.join(' ');
};

export const getFormatDateOnlyNum = (
  date: string | undefined,
  format: string[] = ['year', 'month', 'day'],
  shortYear: boolean = false,
) => {
  if (date === undefined) {
    return '';
  }
  const [year, month, day] = date.split('-');

  const fullYear = shortYear ? formatShortYear(year) : year;

  const resultArray = format.map((element) => {
    switch (element) {
      case 'year':
        return `${fullYear}`;
      case 'month':
        return `${month}`;
      case 'day':
        return `${day}`;
      default:
        return ''; // Handle unknown format gracefully
    }
  });

  return resultArray.join(' ');
};

export const asYYYYMM = (date: string | Date | Dayjs | undefined) => {
  if (date === undefined) {
    return '';
  }

  return dayjs(date).format('YYYY.MM').toString();
};

export const asYYYYMMDD = (date: string | undefined, shortYear: boolean = false) => {
  if (date === undefined) {
    return '';
  }

  const [fullDate, time] = date.split(' ');
  const [year, month, day] = fullDate.split('-');
  const fullYear = shortYear ? formatShortYear(year) : year;
  return `${fullYear}.${month}.${day}`;
};

export const formatYYYYMM = (date: Date): string => {
  return dayjs(date).format('YYYY-MM')?.toString();
};

export const formatYYYYMMDD = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD')?.toString();
};

export const calculateTimeAgo = (dateTime: string): string => {
  const now = new Date();
  const diffInMilliseconds = now.getTime() - new Date(dateTime).getTime();
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  if (diffInSeconds < 60) {
    return '방금 전';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}분 전`;
  } else if (diffInHours < 24) {
    return `${diffInHours}시간 전`;
  } else if (diffInDays < 7) {
    return `${diffInDays}일 전`;
  } else if (diffInWeeks < 5) {
    return `${diffInWeeks}주 전`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths}개월 전`;
  } else {
    return `${diffInYears}년 전`;
  }
};

export const getFormatMessageDateTime = (dateTime: string | undefined): string => {
  if (dateTime === undefined) {
    return '';
  }

  const options: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  const formattedTime = new Date(dateTime).toLocaleTimeString('ko-KR', options);
  return formattedTime;
};

export const padZero = (value: number) => (value < 10 ? `0${value}` : value);

export const truncateText = (text: string | undefined, maxLength: number) => {
  if (text === undefined) {
    return;
  }
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '···';
  } else {
    return text;
  }
};

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getCurrentYearMonth = (): string => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  return `${year}-${month}`;
};

export const now = (): string => {
  return new Date().toDateString();
};

export const formatDistanceToThousandKm = (num: string | null | undefined, decimalPlaces: number = 0) => {
  if (num === null || num === undefined) {
    return '0';
  }

  const parsedNum = Number(num);
  if (isNaN(parsedNum)) {
    return '0';
  }

  const roundedNum = parsedNum / 10000;
  return roundedNum.toFixed(decimalPlaces);
};

export const formatDistanceToKm = (num: string | null | undefined, decimalPlaces: number = 0) => {
  if (num === null || num === undefined) {
    return '0';
  }

  const parsedNum = Number(num);
  if (isNaN(parsedNum)) {
    return '0';
  }

  const roundedNum = parsedNum / 1000;
  return roundedNum.toFixed(decimalPlaces);
};

export interface UpdateSearchParamsResult {
  updatedParams: any;
  error: boolean;
  errorMsg: string;
}

export const updateSearchParams = (searchParams: any, key: string, value: number): UpdateSearchParamsResult => {
  const convertedValue = Math.floor((value / 1000) * 100) / 100;
  let error = false;
  let errorMsg = '';
  switch (key) {
    case 'minLoadedInnerLength':
      if (Number(convertedValue) < 1) {
        error = true;
        errorMsg = LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE;
      } else if (Number(convertedValue) > 10.5) {
        error = true;
        errorMsg = LOADED_INNER_LENGTH_IS_NOT_OVER_TEN_POINT_FIVE;
      }
      break;
    case 'maxLoadedInnerLength':
      if (Number(convertedValue) < 1) {
        error = true;
        errorMsg = LOADED_INNER_LENGTH_IS_NOT_UNDER_ONE;
      } else if (Number(convertedValue) > 10.5) {
        error = true;
        errorMsg = LOADED_INNER_LENGTH_IS_NOT_OVER_TEN_POINT_FIVE;
      }
      break;
    case 'loadedInnerLength':
      error = Number(convertedValue) < 1 || Number(convertedValue) > 10.5;
      errorMsg = LOADED_INNER_LENGTH_RANGE_ERROR_MESSAGE;
      break;
    case 'loadedInnerArea':
      error = Number(convertedValue) < 1;
      errorMsg = MIN_LENGTH_ERROR_MESSAGE;
      break;
    case 'loadedInnerHeight':
      error = Number(convertedValue) < 1;
      errorMsg = MIN_LENGTH_ERROR_MESSAGE;
      break;
  }

  const updatedParams = { ...searchParams, [key]: String(convertedValue) };

  return { updatedParams, error, errorMsg };
};

export const convertToTextContents = (message: string) => {
  return `{"text":"${message}"}`;
};

export const truncateDecimal = (num: number | undefined): number | null => {
  if (num === null || num === undefined) {
    return null;
  }

  const roundedNum = Math.round(num * 10) / 10;
  return roundedNum;
};

export const formatJobTitle = (item: Job): string => {
  if (item.minTons == item.maxTons) {
    return item.minTons + '톤 ' + item.title;
  }
  return item.minTons + '톤 ~ ' + item.maxTons + '톤 ' + item.title;
};

export const formatHour = (hour: number): string => {
  return hour < 10 ? `0${hour}:00` : `${hour}:00`;
};

export const formatTimeRange = (startHour: number, endHour: number): string => {
  const formattedStartHour = formatHour(startHour);
  const formattedEndHour = formatHour(endHour);
  return `${formattedStartHour} ~ ${formattedEndHour}`;
};

export const phoneCall = (phoneNumber: string | undefined) => {
  if (phoneNumber) {
    window.location.href = `tel:${phoneNumber}`;
  }
};

// 객체 비교
export const compareObjects = (obj1: any, obj2: any): boolean => {
  if (obj1 === obj2) return true;

  if (obj1 instanceof Set && obj2 instanceof Set) {
    return areSetsEqual(obj1, obj2);
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];
    if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!compareObjects(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }
  return true;
};

export const isSet = (value: any) => {
  return value instanceof Set;
};

export const areSetsEqual = (setA: Set<any>, setB: Set<any>) => {
  if (setA.size !== setB.size) return false;

  for (let elem of setA) {
    if (!setB.has(elem)) return false;
  }

  return true;
};

// 배열 비교
export const compareArrays = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
      if (!compareObjects(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
};

export const convertToAMPM = (time: number): string => {
  const hours: number = time >= 12 ? time - 12 : time;
  const ampm: string = time >= 12 ? '오후' : '오전';
  const convertedHours: number = hours === 0 ? 12 : hours; // 0시를 12시로 표시

  // 변환된 시간과 오전/오후를 합쳐서 반환
  return `${ampm} ${convertedHours}시`;
};

export const isExternalUrl = (url: string) => {
  return url.startsWith('http://') || url.startsWith('https://');
};

export const isUrlFromOurDomain = (url: string): boolean => {
  const domain = process.env.REACT_APP_SERVER_URL || 'https://zigtruck.io';
  return url.includes(domain);
};

/**
 * 요일 가져오는 함수
 * @param date
 * @returns
 */
export const getDayOfWeek = (date: string) => {
  return dayjs(date).format('dddd');
};

export const getInputErrorMessage = (comparison: string, type: string) => {
  const messages: { [key: string]: { [key: string]: string } } = {
    max: {
      year: '최소연식보다 커야 합니다.',
      tons: '최소톤수보다 커야 합니다.',
      length: '최소길이보다 커야 합니다.',
      default: '최소값보다 커야 합니다.',
    },
    min: {
      year: '최대연식보다 작아야 합니다.',
      tons: '최대톤수보다 작아야 합니다.',
      length: '최대길이보다 작아야 합니다.',
      default: '최대값보다 작아야 합니다.',
    },
  };

  if (!messages[comparison]) {
    throw new Error('비교 유형이 잘못되었습니다. "max" 또는 "min"이어야 합니다.');
  }

  return messages[comparison][type] || messages[comparison].default;
};

/**
 * 주소에서 시군구 추출
 * @param address
 * @returns
 */
export const extractCityCounty = (address: string): string => {
  // 시, 군, 구를 배열로 정의
  const targets = ['시', '군', '구'];

  // 문자열에서 시군구 위치 찾기
  let lastIndex = -1;

  // 주소의 길이까지 반복하며 각 문자에서 시군구 여부 확인
  for (const target of targets) {
    // target의 마지막 인덱스 찾기
    const index = address.lastIndexOf(target);

    // index가 유효하고 현재 찾은 인덱스가 더 크면 업데이트
    if (index > lastIndex) {
      lastIndex = index;
    }
  }

  // 마지막 인덱스가 -1이 아니면 해당 인덱스까지 자르기
  if (lastIndex !== -1) {
    // 시군구까지 포함된 부분을 반환
    return address.slice(0, lastIndex + 1).trim();
  }

  // 시군구를 찾을 수 없을 때 원본 주소 반환
  return address;
};

// 입력값을 지정한 소수점 자리까지 포맷팅하는 함수
export const formatValueToDecimalPlaces = (value: string, decimalPlaces: number): string => {
  // 소수점과 숫자만 남기고 나머지 문자를 제거
  const cleanedValue = String(value).replace(/[^\d.]/g, '');

  // 소수점 이하 자리수 제어
  if (cleanedValue.includes('.')) {
    const parts = cleanedValue.split('.');
    // 소수점 이하 지정된 자리수까지만 유지
    return `${parts[0]}.${parts[1].slice(0, decimalPlaces)}`;
  } else {
    return cleanedValue;
  }
};

// 글자 수 계산
export const calculateTextLength = (text: string) => {
  let length = 0;
  for (let i = 0; i < text.length; i++) {
    if (text.charCodeAt(i) > 127) {
      // 한글
      length += 2;
    } else {
      // 영어, 숫자
      length += 1;
    }
  }
  return length;
};

// 토큰 만료 여부 체크
export const isTokenExpired = (token: string): boolean => {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const currentTime = Math.floor(Date.now() / 1000);

    return payload.exp && payload.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const isUnderFourTons = (tons: string | number | undefined) => {
  return !!tons && Number(tons) <= 4;
};
