import { atom } from 'jotai';

import { getCurrentYear } from '@/utils/common';

const currentYear = getCurrentYear();

const initialLicenseSearchParamsState: LicenseSearch = {
  useClassification: { code: '', desc: '' },
  minYear: '2000',
  maxYear: (currentYear + 1).toString(),
  minTons: '1',
  maxTons: '27',
  licenseSalesType: '',
  licenseType: { code: '', desc: '' },
  page: 1,
  memberId: null,
};

export const licenseSearchParamsAtom = atom<LicenseSearch>(initialLicenseSearchParamsState);
